/**
 * @file
 * Asynchronous store actions for fetching data.
 */

import axios from "axios";
import normalize from "json-api-normalizer";
import { denormalize, buildIncludeString, camelcase } from "@/api/helpers";
import { api, defaultParams, normalizerOptions } from "@/api/operations";

function commitAll(commit, response) {
  Object.keys(response).forEach((key) => {
    commit(`SET_${key.toUpperCase().replace("-", "_")}`, response[key]);
  });
}

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// ############ Actions #################

export const changeRoute = ({ commit }, route) => {
  window.history.pushState({}, "mirr.OS", route);
  commit("CHANGE_ROUTE", route);
};

export const fetchAll = async ({ commit, dispatch }) => {
  Promise.all([
    dispatch("fetchSettings"),
    dispatch("fetchWidgets"),
    dispatch("fetchSources"),
    dispatch("fetchGroups"),
    dispatch("fetchSystemStatus"),
  ]).then(() => {
    commit("CHANGE_FETCH_STATUS", false);
  });
};

export const fetchSystemStatus = async ({ commit, dispatch }) => {
  try {
    const res = await axios.get("/system/status");
    commit("SET_SYSTEMSTATUS", res.data.meta);
    commit("CHANGE_PREMIUM_FEATURES_ENABLED", res.data.meta.registered);
    commit("clearErrors"); // FIXME: This also resets other errors
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchWidgets = async ({ commit, dispatch }, includes = []) => {
  try {
    const includeString = buildIncludeString(includes);
    const response = await axios.get(`/widgets${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchWidgetInstances = async (
  { commit, dispatch },
  includes = []
) => {
  const includeString = buildIncludeString(includes);
  try {
    const response = await axios.get(`/widget-instances${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchGroups = async ({ commit, dispatch }, includes = []) => {
  const includeString = buildIncludeString(includes);

  try {
    const response = await axios.get(`/groups${includeString}`);
    const normalizedResponse = normalize(response.data, normalizerOptions);
    commit("SET_GROUPS", normalizedResponse.groups);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchSources = async ({ commit, dispatch }, includes = []) => {
  try {
    const includeString = buildIncludeString(includes);
    const response = await axios.get(`/sources${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchSource = async (
  { commit, dispatch },
  { sourceId, includes = [] }
) => {
  try {
    const includeString = buildIncludeString(includes);
    const response = await axios.get(`/sources/${sourceId}${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchSettings = async ({ commit, dispatch }) => {
  try {
    const response = await axios.get("/settings");
    const normalized = normalize(response.data, normalizerOptions);
    commit("SET_SETTINGS", normalized.settings);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchSetting = async ({ commit, dispatch }, setting) => {
  try {
    const response = await axios.get(`/settings/${setting}`);
    const normalized = normalize(response.data, normalizerOptions);

    commit("SET_SETTINGS", normalized.settings);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const updateSetting = async (
  { commit, dispatch },
  { resource, notification = undefined }
) => {
  try {
    if (resource.id === "system_timezone") {
      commit("TOGGLE_BLOCKING_STATUS");
    }
    const response = await axios.patch(
      `/settings/` + resource.id,
      denormalize(resource),
      defaultParams
    );
    const normalized = normalize(response.data);
    commit("SET_SETTINGS", normalized.settings);
    dispatch("handleNotification", {
      status: response.status,
      message: notification,
    });

    if (resource.id === "system_timezone") {
      commit("TOGGLE_BLOCKING_STATUS");
    }
  } catch (error) {
    if (resource.id === "system_timezone") {
      commit("TOGGLE_BLOCKING_STATUS");
    }
    dispatch("handleError", error);
  }
};

/**
 *
 *
 * @param {*} { commit }
 * @param {*} { parent, child, includes = [] }
 */
export const fetchSubresource = async (
  { commit, dispatch },
  { parentResource, parentId, childResource, includes = [] }
) => {
  try {
    const data = await api.getSubresource({
      parentResource: parentResource,
      parentId: parentId,
      childResource: childResource,
      includes: includes,
    });
    commitAll(commit, data);
  } catch (error) {
    dispatch("handleError", error);
  }
};

// ################## INSTANCES #####################
export const initExtensionInstance = async (
  { commit, dispatch },
  { type, payload, includes = [] }
) => {
  try {
    payload = denormalize(payload);
    const includeString = buildIncludeString(includes);
    const response = await axios.post(
      `${type}${includeString}`,
      payload,
      defaultParams
    );

    let data = normalize(response.data);
    commitAll(commit, data);
    dispatch("handleNotification", { status: response.status });
  } catch (error) {
    dispatch("handleError", error);
  }
};

export /**
 * Updates the settings of an extension instance.
 *
 * @param {*} { commit, dispatch }
 * @param {*} { type, payload } JSON:API resource type and the actual resource.
 */
const updateExtensionInstance = async (
  { commit, dispatch },
  { type, payload }
) => {
  try {
    payload = denormalize(payload);
    const response = await axios.patch(
      `${type}/${payload.data.id}`,
      payload,
      defaultParams
    );
    switch (response.status) {
      case 200:
      case 201: {
        const normalized = normalize(response.data);
        commitAll(commit, normalized);
        // @FIXME: This is also triggered on widget moves, which can get annoying
        dispatch("handleNotification", {
          status: response.status,
        });
        break;
      }
      default:
        break;
    }
  } catch (error) {
    dispatch("handleError", error);
  }
};

/**
 * Deletes an extension instance in the backend.
 *
 * @param {*} { commit, dispatch } Supplied by vuex.
 * @param {*} { type, payload } JSON:API resource type and the resource id to be deleted.
 */
export const deleteExtensionInstance = async (
  { commit, dispatch },
  { type, payload }
) => {
  try {
    const response = await axios.delete(`/${type}/${payload}`);

    switch (response.status) {
      case 200:
      case 204:
        commit("DELETE_EXTENSION_INSTANCE", {
          type: camelcase(type),
          payload: payload,
        });
        dispatch("handleNotification", {
          status: response.status,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch("handleError", error);
  }
};

// ################### INSTANCE ASSOCIATIONS ######################

export const fetchInstanceAssociationsForWidgetInstance = async (
  { commit, dispatch },
  widgetInstanceId
) => {
  try {
    const response = await axios.get(
      `/widget-instances/${widgetInstanceId}/instance-associations`
    );
    const normalized = normalize(response.data, normalizerOptions);

    commit("SET_INSTANCEASSOCIATIONS", normalized.instanceAssociations);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const createInstanceAssociation = async (
  { commit, dispatch },
  payload
) => {
  try {
    const response = await api.createInstanceAssociation(payload);
    commit("UPDATE_INSTANCE_ASSOCIATIONS", response.instanceAssociations);
    dispatch("handleNotification", {
      status: 201,
    });
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const updateInstanceAssociation = async (
  { commit, dispatch },
  payload
) => {
  try {
    const res = await api.patchInstanceAssociation(payload);
    commit("UPDATE_INSTANCE_ASSOCIATIONS", res.instanceAssociations);
    dispatch("handleNotification", { status: 200 });
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const deleteInstanceAssociation = async (
  { commit, dispatch },
  payload
) => {
  try {
    await api.deleteInstanceAssociation(payload);
    commit("REMOVE_INSTANCEASSOCIATION", payload);
    dispatch("handleNotification", { status: 204 });
  } catch (error) {
    dispatch("handleError", error);
  }
};

// ################### Extensions ######################

export const installExtension = async ({ commit, dispatch }, resource) => {
  const req = denormalize(resource);
  try {
    commit("SET_BACKEND_RESTART_STATUS", true);
    const res = await axios.post(`/${resource.type}`, req, defaultParams);
    const data = normalize(res.data);
    commit("INSTALL_EXTENSION", data[resource.type][resource.attributes.name]);
  } catch (error) {
    dispatch("handleError", error);
  } finally {
    commit("SET_BACKEND_RESTART_STATUS", false);
  }
};

export const updateExtension = async ({ commit, dispatch }, resource) => {
  try {
    commit("SET_BACKEND_RESTART_STATUS", true);
    const res = await axios.patch(
      `/${resource.type}/` + resource.attributes.name,
      denormalize(resource),
      defaultParams
    );
    const data = normalize(res.data);
    commit("UPDATE_EXTENSION", data[resource.type][resource.attributes.name]);
  } catch (error) {
    dispatch("handleError", error);
  } finally {
    commit("SET_BACKEND_RESTART_STATUS", false);
  }
};

export const disableExtension = async ({ commit, dispatch }, resource) => {
  try {
    const res = await axios.patch(
      `/${resource.type}/${resource.id}`,
      denormalize({
        id: resource.id,
        type: resource.type,
        attributes: { active: false },
      }),
      defaultParams
    );
    const data = normalize(res.data, normalizerOptions);
    commit("UPDATE_EXTENSION", data[resource.type][resource.id]);
    const staleInstances = `${resource.type.slice(0, -1)}Instances`;
    resource.relationships[staleInstances].data.forEach((instance) =>
      commit("DELETE_EXTENSION_INSTANCE", {
        type: staleInstances,
        payload: instance.id,
      })
    );
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const enableExtension = async ({ commit, dispatch }, resource) => {
  try {
    const response = await axios.patch(
      `/${resource.type}/${resource.id}`,
      denormalize({
        id: resource.id,
        type: resource.type,
        attributes: { active: true },
      }),
      defaultParams
    );
    if (response.status === 200) {
      commit("ENABLE_EXTENSION", resource);
    }
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const uninstallExtension = async ({ commit, dispatch }, extension) => {
  try {
    commit("SET_BACKEND_RESTART_STATUS", true);
    const response = await axios.delete(`/${extension.type}/${extension.id}`);
    if (response.status === 204) {
      commit("UNINSTALL_EXTENSION", extension);
    }
  } catch (error) {
    dispatch("handleError", error);
  } finally {
    commit("SET_BACKEND_RESTART_STATUS", false);
  }
};

// #################### System / UI ####################
export const handleNotification = ({ commit }, payload) => {
  setTimeout(() => {
    commit("CLEAR_OLDEST_NOTIFICATION");
  }, 2000);
  commit("ADD_NOTIFICATION", payload);
};

export const handleError = ({ commit }, error) => {
  if (error.response) {
    // Server responded
    if (error.response.data.errors) {
      // Response contains errors from the backend
      commit("ADD_ERRORS", error.response.data.errors);
    } else {
      // Response is likely a proxy error from nginx
      commit("SET_NETWORK_ERROR", true);
    }
  } else {
    // Network failure occurred
    commit("SET_NETWORK_ERROR", true);
  }
};

// BOARDS

export const loadBoard = async ({ commit, dispatch }, boardId) => {
  try {
    await dispatch("fetchBoard", { boardId: boardId });
    commit("SET_LOADED_BOARD", boardId);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchBoards = async ({ commit, dispatch }, { includes = [] }) => {
  const includeString = buildIncludeString(includes);
  try {
    const response = await axios.get(`/boards${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const fetchBoard = async (
  { commit, dispatch },
  { boardId, includes = ["widgetInstances"] }
) => {
  const includeString = buildIncludeString(includes);
  try {
    const response = await axios.get(`/boards/${boardId}/${includeString}`);
    const normalized = normalize(response.data, normalizerOptions);
    commitAll(commit, normalized);
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const createBoard = async ({ commit, dispatch }, payload) => {
  try {
    const res = await api.createBoard(payload);
    commit("UPDATE_BOARDS", res.boards);
    dispatch("handleNotification", { status: 201 });
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const updateBoard = async ({ commit, dispatch }, payload) => {
  try {
    const res = await api.updateBoard(payload);
    commit("UPDATE_BOARDS", res.boards);
    dispatch("handleNotification", { status: 200 });
  } catch (error) {
    dispatch("handleError", error);
  }
};

/**
 * Deletes a board on the backend and in the store.
 * @param {number|string} boardId
 */
export const deleteBoard = async ({ state, commit, dispatch }, boardId) => {
  try {
    const res = await api.deleteBoard(boardId);
    commit("DELETE_BOARD", boardId);
    dispatch("handleNotification", { status: res.status });
    if (state.loadedBoard) {
      commit("SET_LOADED_BOARD", undefined);
    }
  } catch (error) {
    dispatch("handleError", error);
  }
};

export const deleteWidgetInstanceFromBoard = async (
  { commit, dispatch },
  { boardId, widgetInstanceId }
) => {
  try {
    const response = await api.deleteWidgetInstanceFromBoard({
      boardId,
      widgetInstanceId,
    });
    // Refresh the board resource to remove dangling instance references
    await dispatch("fetchBoard", { boardId });
    commit("DELETE_EXTENSION_INSTANCE", {
      type: "widgetInstances",
      payload: widgetInstanceId,
    });
    dispatch("handleNotification", {
      status: response.status,
    });
  } catch (error) {
    dispatch("handleError", error);
  }
};
