<template>
  <div
    ref="gridWrapper"
    class="grid__wrapper"
    :class="boardOrientation"
    @scroll.passive="setScrollState"
  >
    <div
      ref="grid"
      :id="`widgetGrid-${editingBoardId}`"
      class="grid-stack"
      :class="boardOrientation"
      v-gridstack
      :style="{
        maxWidth: gridSize.maxWidth + 'px',
        maxHeight: gridSize.maxHeight + 'px',
      }"
      :gs-max-row="gridSize.row"
      :gs-current-row="gridSize.row"
    >
      <GridContainerItem
        v-for="(instance, key) in widgetInstancesForActiveBoard"
        :key="key"
        :id="instance.id"
        :locked="gridLocked"
        @init-gridstack-widget="initGridstackWidget"
      />
    </div>
  </div>
</template>

<script>
import { GridStack } from "gridstack";
import "gridstack/dist/jq/gridstack-dd-jqueryui";

import GridContainerItem from "./GridContainerItem.vue";
import { WidgetInstanceResource } from "@/api/models";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "GridContainer",
  components: {
    GridContainerItem,
  },
  props: {
    editingBoardId: {
      type: String,
      required: true,
    },
  },
  gridstackOptions: {
    alwaysShowResizeHandle: true,
    acceptWidgets: true,
    animate: true,
    cellHeight: 0,
    disableOneColumnMode: true,
    disableResize: false,
    draggable: {
      scroll: true,
      handle: ".widget__drag-container",
    },
    resizable: {
      handles: "e,s,w,n",
    },
    dragIn: ".grid-stack-item",
    dragInOptions: {
      revert: "invalid",
      scroll: true,
      appendTo: ".grid-stack",
      helper: "clone",
      handle: ".grid-stack-item-content",
    },
    dragOut: false,
    float: true,
    margin: 0,
    static: true,
  },
  data: function () {
    return {
      gridInstance: null,
      unsubscribeFn: undefined,
      timeout: null,
    };
  },
  watch: {
    gridLocked: {
      immediate: true,
      handler: function (newVal) {
        this.$nextTick(function () {
          this.gridInstance.setStatic(newVal);
        });
      },
    },
  },
  computed: {
    ...mapState([
      "widgets",
      "gridLocked",
      "widgetInstances",
      "pageScrolled",
      "systemStatus",
    ]),
    ...mapGetters(["boardOrientation", "widgetInstanceIdsForBoardId"]),
    widgetInstancesForActiveBoard: function () {
      return this.widgetInstanceIdsForBoardId(this.editingBoardId).reduce(
        (acc, id) => {
          acc[id] = this.widgetInstances[id];
          return acc;
        },
        {}
      );
    },
    gridSize() {
      const displaySize = this.systemStatus?.client_display ?? {
        width: 1080,
        height: 1920,
      };
      // 80px cell width + 10px gutter. We add 10px to the available space to account for an outer padding of 5px.
      const cellSize = 90;

      let maxWidth = null;
      let maxHeight = null;

      // If the available display size exactly fits n columns/rows, we use that as maximum width/height for the grid. Otherwise, this constrains the grid to the dimensions which fit an integer amount of columns/rows.
      if (displaySize.width % cellSize === 0) {
        maxWidth = displaySize.width;
      } else {
        maxWidth = displaySize.width - ((displaySize.width + 10) % cellSize);
      }

      if (displaySize.height % cellSize === 0) {
        maxHeight = displaySize.height;
      } else {
        maxHeight = displaySize.height - ((displaySize.height + 10) % cellSize);
      }

      return {
        column: Math.floor((displaySize.width + 10) / cellSize),
        row: Math.floor((displaySize.height + 10) / cellSize),
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      };
    },
  },
  mounted() {
    this.setGridStyles();
    this.gridInstance = GridStack.init(
      {
        ...this.$options.gridstackOptions,
        ...{ column: this.gridSize.column, row: this.gridSize.row },
      },
      `#widgetGrid-${this.editingBoardId}`
    );

    this.unsubscribeFn = this.$store.subscribeAction(
      this.handleWidgetInstanceDeletion
    );

    // Handle window resizing to re-calculate the grid styles.
    window.addEventListener("resize", () => {
      // clear the timeout
      clearTimeout(this.timeout);
      // start timing for event "completion"
      this.timeout = setTimeout(this.setGridStyles, 250);
    });
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.setGridStyles);
    this.unsubscribeFn();
    this.gridInstance.destroy();
  },
  directives: {
    // Set up event listeners in directive to have the VNode context available.
    gridstack: {
      inserted: function (el, binding, vnode) {
        vnode.context.$nextTick(() => {
          vnode.context.gridInstance.on(
            "dropped",
            function (event, previousWidget, newWidget) {
              vnode.context.saveNewWidget(newWidget);
            }
          );
          vnode.context.gridInstance.on("change", function (event, items) {
            // Prevent errors if the event triggered prematurely.
            if (items === undefined) return;

            items.forEach((item) => {
              // Change also happens on addWidget, but the item doesn't have an ID yet. Ensure we don't save the new widget twice.
              if (item.id) {
                vnode.context.updateWidget(item);
              }
            });
          });
        });
      },
    },
  },
  methods: {
    ...mapActions(["initExtensionInstance", "handleNotification"]),
    setGridStyles() {
      this.$store.commit("SET_GRID_SIZE", this.gridSize);

      let height = null;
      let width = null;

      if (this.boardOrientation === "portrait") {
        width = "calc(100vw - 10px)"; // Account for borders
        height = `${Math.min(
          Math.floor(
            (this.gridSize.maxHeight / this.gridSize.maxWidth) *
              this.$refs.gridWrapper.clientWidth
          ),
          this.gridSize.maxHeight
        )}px`;
      } else {
        width = `${Math.min(
          Math.floor(
            (this.gridSize.maxWidth / this.gridSize.maxHeight) *
              this.$refs.gridWrapper.clientHeight
          ),
          this.gridSize.maxWidth
        )}px`;
        height = "100%"; // Use
      }

      this.$refs.grid.style.setProperty("--grid-columns", this.gridSize.column);
      this.$refs.grid.style.setProperty("--grid-rows", this.gridSize.row);
      this.$refs.grid.style.setProperty(
        "--grid-width",
        this.gridSize.column * 90
      );
      this.$refs.grid.style.setProperty("width", width);
      this.$refs.grid.style.setProperty("height", height);

      let rules = [];
      for (let i = 0; i <= this.gridSize.column; i++) {
        rules.push(
          `.grid-stack > .grid-stack-item[gs-x="${i}"] { 
            left: ${(100 / this.gridSize.column) * i}%;
          }`
        );
        rules.push(
          `.grid-stack > .grid-stack-item[gs-w="${i}"] { width: ${
            (100 / this.gridSize.column) * i
          }% }`
        );
      }
      for (let i = 0; i <= this.gridSize.row; i++) {
        rules.push(
          `.grid-stack > .grid-stack-item[gs-y="${i}"] { top: ${
            (100 / this.gridSize.row) * i
          }% }`
        );
        rules.push(
          `.grid-stack > .grid-stack-item[gs-h="${i}"] { height: ${
            (100 / this.gridSize.row) * i
          }% }`
        );
      }

      rules.push(
        `.grid-stack { background-size: ${100 / this.gridSize.column}% ${
          100 / this.gridSize.row
        }%; }`
      );

      document.head.insertAdjacentHTML(
        "beforeend",
        `<style type="text/css">${rules.join("\n")}</style>`
      );
    },
    setScrollState(e) {
      const el = e.target;
      this.$store.commit("CHANGE_PAGESCROLL_STATE", {
        top: el.scrollTop > 0,
        bottom: el.scrollHeight - el.scrollTop === el.clientHeight,
      });
    },
    initGridstackWidget(widget) {
      this.gridInstance?.makeWidget(widget);
    },
    saveNewWidget(newWidget) {
      const widgetId =
        newWidget.el.attributes.getNamedItem("data-widget-id").value;
      this.gridInstance.removeWidget(newWidget.el);

      if (newWidget.y >= this.gridSize.row.toFixed()) {
        return;
      }

      let data = new WidgetInstanceResource();
      data.setWidgetRelationship(widgetId);
      data.setBoardRelationship(this.editingBoardId);

      data.setPosition({
        x: newWidget.x,
        y: newWidget.y,
        width: newWidget.w,
        height: newWidget.h,
      });

      this.$store.dispatch("initExtensionInstance", {
        type: "widget-instances",
        payload: data,
        includes: ["board", "widget"],
      });
    },
    updateWidget(item) {
      let data = new WidgetInstanceResource({ id: item.id });
      data.setPosition({
        x: item.x,
        y: item.y,
        width: item.w,
        height: item.h,
      });

      this.$store.dispatch("updateExtensionInstance", {
        type: "widget-instances",
        payload: data,
      });
    },
    handleWidgetInstanceDeletion(action) {
      if (action.type != "deleteWidgetInstanceFromBoard") return;

      this.gridInstance.removeWidget(
        `widget-${action.payload.widgetInstanceId}`,
        false
      );
    },
  },
};
</script>
<style lang="scss">
.grid__wrapper {
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.grid-stack {
  background-image: url("../../assets/board-grid.svg");
  background-size: 90px 90px; // default, should be overridden in style tag.
  background-origin: padding-box;
  border-radius: $global-radius;
  border: 2px solid $light-gray;
  box-sizing: content-box;
  position: relative;
  margin: 0 auto;
}

.grid-stack-placeholder {
  background-color: $medium-gray;
  opacity: 0.5;
  border-radius: $global-radius;
}

// TODO: Refactor to separate file
.grid-stack > .grid-stack-item > .ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}
.grid-stack > .grid-stack-item.ui-resizable-disabled > .ui-resizable-handle,
.grid-stack > .grid-stack-item.ui-resizable-autohide > .ui-resizable-handle {
  display: none;
}
.grid-stack > .grid-stack-item.ui-draggable-dragging,
.grid-stack > .grid-stack-item.ui-resizable-resizing {
  z-index: 100;
}
.grid-stack > .grid-stack-item.ui-draggable-dragging > .grid-stack-item-content,
.grid-stack
  > .grid-stack-item.ui-resizable-resizing
  > .grid-stack-item-content {
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.grid-stack > .grid-stack-item {
  > .ui-resizable-handle {
    width: $resize-handle-size;
    height: $resize-handle-size;
  }
  > .ui-resizable-n,
  > .ui-resizable-s {
    left: calc(50% - #{$resize-handle-size * 0.5});
  }

  > .ui-resizable-w,
  > .ui-resizable-e {
    top: calc(50% - #{$resize-handle-size * 0.5});
  }

  > .ui-resizable-n {
    cursor: n-resize;
    top: 0;
  }
  > .ui-resizable-w {
    cursor: w-resize;
    left: 0;
  }
  > .ui-resizable-s {
    cursor: s-resize;
    bottom: 0;
  }
  > .ui-resizable-e {
    cursor: e-resize;
    right: 0;
  }
}

.grid-stack > .grid-stack-item.ui-draggable-dragging > .ui-resizable-handle {
  display: none !important;
}
</style>
