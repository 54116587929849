<template>
  <div id="app" :class="[environment, boardOrientation]">
    <div v-if="blocking" id="blocking-modal">
      <progress />
      <span>{{ t("Commencing time travel …") }}</span>
    </div>
    <main v-if="fetching" class="spinner__wrapper">
      <AnimatedLoader classes="spinner" />
    </main>

    <main v-else-if="networkError.length > 0" class="fullpage centered">
      <h4>
        {{ t("Error while connecting to the backend") }}
      </h4>
      <p>{{ t(networkError) }}</p>
      <p>
        {{
          t(
            "The server didn't respond in time. This is often a temporary issue, or might happen because you are in a different WiFi network than your smart mirror."
          )
        }}
      </p>
      <button type="button" class="button" @click="reload">
        {{ t("reload") }}
      </button>
    </main>

    <Setup
      v-else-if="
        (!systemStatus.setup_complete && !systemStatus.configured_at_boot) ||
        (systemStatus.setup_complete && !systemStatus.configured_at_boot)
      "
      @setup-finished="toggleStatusPoll('off')"
    />

    <Reconnect v-else-if="systemStatus.online === false" />

    <template v-else>
      <PasswordInputPage v-if="passwordProtectionEnabled && !passwordMatches" />
      <template v-else>
        <template v-if="errors.length > 0">
          <ErrorMessage
            v-for="(error, idx) in errors"
            :key="idx"
            :status="error.status"
            :code="error.code"
            :title="error.title"
            :detail="error.detail"
          />
        </template>
        <TheMainNavigation />
        <PageWrapper />
        <WebappHint
          v-if="environment === 'browser' && mobile && webappHintVisible"
        />
        <vuedal />
        <div v-if="notification" class="notification">
          <transition name="slide-top" mode="out-in" appear>
            <NotificationBubble
              :success="notification.status.toString().startsWith('20')"
              :message="notification.message"
            />
          </transition>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import "@/assets/sass/main.scss";

import Setup from "@/pages/PageSetup.vue";
import Reconnect from "@/pages/PageReconnect.vue";
import TheMainNavigation from "@/components/TheMainNavigation.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import AnimatedLoader from "@/components/AnimatedLoader.vue";

import PageWrapper from "@/pages/PageWrapper.vue";

import PasswordInputPage from "@/pages/PasswordInputPage.vue";

import WebappHint from "@/components/ux/WebappHint.vue";
import NotificationBubble from "@/components/presentation/NotificationBubble.vue";

import { Component as Vuedal, Bus as VuedalsBus } from "vuedals";
import { mapGetters, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Setup,
    Reconnect,
    TheMainNavigation,
    PageWrapper,
    ErrorMessage,
    AnimatedLoader,
    Vuedal,
    WebappHint,
    NotificationBubble,
    PasswordInputPage,
  },
  computed: {
    environment() {
      return window.navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
        ? "appshell"
        : "browser";
    },

    mobile() {
      return window.innerWidth <= 480;
    },

    themeColor() {
      const attr = this.$store.state.settings.system_themecolor;
      return attr && attr.attributes.value;
    },
    ...mapGetters([
      "boardOrientation",
      "language",
      "systemOffline",
      "passwordProtectionEnabled",
    ]),
    ...mapState([
      "blocking",
      "errors",
      "fetching",
      "networkError",
      "notifications",
      "systemStatus",
      "webappHintVisible",
      "backendRestarting",
      "passwordMatches",
    ]),
    notification() {
      return this.notifications.slice(-1).pop();
    },
  },

  watch: {
    language: function (newLang) {
      this.$translate.setLang(newLang);
      document.documentElement.setAttribute("lang", this.languageTag());
    },
    backendRestarting: function (newVal) {
      if (newVal) {
        this.toggleStatusPoll("off");
      } else {
        setTimeout(() => {
          this.$options.statusPoll = this.toggleStatusPoll("on");
        }, 15000);
      }
    },
    themeColor: {
      immediate: true,
      handler: function (newColor) {
        if (newColor != undefined && newColor.length > 0) {
          document.documentElement.style.setProperty("--theme-color", newColor);
          const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
            newColor
          );
          const rgb = result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
              }
            : null;
          document.documentElement.style.setProperty(
            "--theme-color-rgb",
            `${rgb.r}, ${rgb.g}, ${rgb.b}`
          );
        }
      },
    },
  },

  beforeMount: function () {
    this.$store.dispatch("fetchAll");
    this.$options.statusPoll = this.toggleStatusPoll("on");
  },
  mounted: function () {},

  methods: {
    reload: function () {
      // @TODO: Maybe implement a reset action that clears errors
      this.$store.commit("clearErrors");
      this.$store.commit("CHANGE_FETCH_STATUS", true);
      this.$store.dispatch("fetchAll");
    },

    closeModal: function () {
      VuedalsBus.$emit("close");
    },

    languageTag: function () {
      const regex = new RegExp(/([A-Z]{1}[a-z]{1})/g);
      return this.language.replace(regex, (match) => {
        return match.toUpperCase().padStart(match.length + 1, "-");
      });
    },

    toggleStatusPoll: function (toggle) {
      switch (toggle) {
        case "on":
          return setInterval(() => {
            this.$store.dispatch("fetchSystemStatus");
          }, 30000);
        case "off": {
          const id = clearInterval(this.$options.statusPoll);
          delete this.$options.statusPoll;
          return id;
        }
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
html {
  background-color: $primary-color;
}

body {
  @include breakpoint(medium) {
    background-color: $steelblue;
  }
}

html,
body,
#app {
  height: 100%;
}

#app {
  background-color: $white;

  max-width: 100vw;
  margin: 0 auto;

  position: relative;

  display: grid;
  grid-template-rows: 3.55rem 1fr;
  grid-template-columns: none;

  @include breakpoint(medium) {
    border-left: 1px solid $thin;
    border-right: 1px solid $thin;
    max-width: $global-width;
  }

  &.appshell {
    margin-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

main {
  overflow-y: scroll;
}

.fullpage {
  height: 100vh;
  padding: 2rem;
  &.centered {
    text-align: center;
    @include breakpoint(medium) {
      max-width: 50%;
      margin: 1rem auto;
    }
  }
}

.vuedals .vuedal {
  padding: 2rem 3rem;
  z-index: 999;

  &.xs {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0 3rem;
    padding-bottom: env(safe-area-inset-bottom);

    header {
      background-color: var(--theme-color);
      color: $white;
      text-align: center;
      text-transform: uppercase;
      margin: 0 -3rem 1rem -3rem;
      padding: 1rem;
      position: sticky;
      top: 0;
      z-index: 999;
      .close {
        font-size: 1.75rem;
        line-height: 0.75rem;
      }
    }
    .close {
      width: 1rem;
      height: 1rem;
      border: 1px solid $white;
      border-radius: 75%;
      text-align: center;
      float: none;
      position: absolute;
      right: 1rem;
      top: 1.3rem;
    }
  }
}

.notification {
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: absolute;
  top: 0;
}

.appshell .vuedals {
  top: env(safe-area-inset-top);
}

#blocking-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>
