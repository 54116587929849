<template>
  <fieldset>
    <TextInputFloatingLabel for="personal_name" :text="t('Your first name')">
      <input
        id="personal_name"
        name="personal_name"
        type="text"
        autocomplete="given-name"
        required
        v-validate
        :value="personal_name.value"
        placeholder=" "
      />
    </TextInputFloatingLabel>
    <TextInputFloatingLabel for="personal_email" :text="t('E-mail address')">
      <input
        id="personal_email"
        name="personal_email"
        type="email"
        autocomplete="email"
        required
        v-validate
        :value="personal_email.value"
        placeholder="mail@example.com"
      />
    </TextInputFloatingLabel>
  </fieldset>
</template>
<script>
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

export default {
  name: "PersonalSettingsBlock",
  components: {
    TextInputFloatingLabel,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    personal_name: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    personal_email: {
      type: Object,
      required: true,
    },
  },
};
</script>
