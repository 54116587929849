<template>
  <fieldset>
    <p v-if="boardRotationEnabled">
      {{
        t(
          "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation."
        )
      }}
    </p>
    <template v-if="rule">
      <h6>{{ t("active_rules_heading") }}</h6>
      <p>
        {{
          $options.ruleOptions.operators[rule.attributes.operator].formatter(
            t(rule.attributes.field),
            t(rule.attributes.operator),
            rule.attributes.value
          )
        }}
      </p>
      <button type="button" class="button alert expanded" @click="deleteRule">
        {{ t("remove rule") }}
      </button>
    </template>

    <template v-else>
      <h6>{{ t("New rule") }}</h6>
      <form @submit.prevent="saveRule" class="rule-form">
        <input type="hidden" name="provider" value="system" />
        <select
          name="field"
          id="field"
          class="rule-form__field"
          v-model="selectedField"
          @change="
            selectedOperator =
              compatibleOperators.length === 1 ? compatibleOperators[0] : ''
          "
        >
          <option value="timeOfDay" selected>
            {{ t("timeOfDay") }}
          </option>
          <option value="dateAndTime" :disabled="!premiumFeaturesEnabled">
            {{ t("dateAndTime") }}
            <span v-if="!premiumFeaturesEnabled"
              >({{ t("premium_feature_notice") }})</span
            >
          </option>
        </select>
        <select
          name="operator"
          id="operator"
          v-model="selectedOperator"
          required
        >
          <option value="" disabled>
            {{ t("operator_select_label") }}
          </option>
          <option
            v-for="operator in compatibleOperators"
            :value="operator"
            :key="operator"
          >
            {{ t(operator) }}
          </option>
        </select>

        <component
          v-if="selectedOperator"
          :is="$options.ruleOptions.operators[selectedOperator].component"
          v-bind="$options.ruleOptions.operators[selectedOperator].props"
          :label="
            t($options.ruleOptions.operators[selectedOperator].valueLabel)
          "
          @input="(e) => (value = e)"
        />

        <div class="buttonrow--inline">
          <button
            type="button"
            class="button alert button--symbol"
            :disabled="!formHasValues"
            @click="clearForm"
          >
            {{ t("cancel") }}
          </button>
          <input
            type="submit"
            class="button button--symbol primary"
            :value="t('add rule')"
            :disabled="ruleFormIncomplete"
          />
        </div>
      </form>
    </template>
  </fieldset>
</template>

<script>
import { RuleResource } from "@/api/models";
import { api } from "@/api/operations";

import NumericOperatorInput from "./NumericOperatorInput";
import RangeOperatorInput from "./RangeOperatorInput";
import BetweenDatesOperatorInput from "./BetweenDatesOperatorInput";
import { mapGetters, mapState } from "vuex";

export default {
  name: "BoardSettingsRuleBlock",
  components: {
    NumericOperatorInput,
    RangeOperatorInput,
    BetweenDatesOperatorInput,
  },
  ruleOptions: {
    // TODO: Refactor this to leverage backend structures.
    operators: {
      before: {
        compatibleFields: ["timeOfDay"],
        component: NumericOperatorInput,
        props: {
          min: 0,
          max: 24,
        },
        valueLabel: "hour",
        formatter: function (field, operator, value) {
          return `${field} ${operator} ${value}:00h`;
        },
      },
      after: {
        compatibleFields: ["timeOfDay"],
        component: NumericOperatorInput,
        props: {
          min: 0,
          max: 24,
        },
        valueLabel: "hour",
        formatter: function (field, operator, value) {
          return `${field} ${operator} ${value}:00h`;
        },
      },
      between: {
        compatibleFields: ["timeOfDay"],
        component: RangeOperatorInput,
        props: {
          min: 0,
          max: 24,
        },
        valueLabel: "hour",
        formatter: function (field, operator, value) {
          return `${field} ${operator} ${value.start}h – ${value.end}h`;
        },
      },
      betweenDates: {
        compatibleFields: ["dateAndTime"],
        component: BetweenDatesOperatorInput,
        props: {
          min: 0,
          max: 24,
        },
        valueLabel: "date",
        formatter: function (field, operator, value) {
          // Vuex getters not present yet; we'll use the browser locale as it's usually identical.
          const formatter = new Intl.DateTimeFormat(window.navigator.language, {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "short",
            //dateStyle: "long",
            //timeStyle: "long", TODO: No support in Safari yet, revisit later
          });
          return `${field}: ${formatter.format(
            Date.parse(value.start)
          )} - ${formatter.format(Date.parse(value.end))}`;
        },
      },
    },
  },
  props: {
    boardId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      rule: "",
      selectedField: "timeOfDay",
      selectedOperator: "",
      value: undefined,
    };
  },
  computed: {
    ...mapState(["premiumFeaturesEnabled"]),
    ...mapGetters(["boardRotationEnabled"]),

    ruleFormIncomplete: function () {
      return this.selectedOperator === undefined || this.value === undefined;
    },
    formHasValues: function () {
      return this.selectedOperator !== "" || this.value !== undefined;
    },
    compatibleOperators: function () {
      if (this.selectedField.length === 0) {
        return [];
      }
      let compatible = [];
      for (const [operator, attributes] of Object.entries(
        this.$options.ruleOptions.operators
      )) {
        if (attributes.compatibleFields.includes(this.selectedField)) {
          compatible.push(operator);
        }
      }

      return compatible;
    },
  },
  beforeMount: function () {
    this.fetchRulesForBoard();
  },
  methods: {
    fetchRulesForBoard: async function () {
      try {
        const response = await api.getRulesForBoard(this.boardId);
        this.rule = response.data.data[0];
      } catch (error) {
        this.error = error;
      }
    },
    saveRule: async function () {
      // TODO: Switch to form inputs once other providers are enabled.
      let resource = new RuleResource();

      // FIXME: dynamic provider.
      resource.setAttribute("provider", "system");
      // FIXME: dynamic field.
      resource.setAttribute("field", this.selectedField);
      resource.setAttribute("operator", this.selectedOperator);
      resource.setAttribute("value", this.value);

      resource.setBoardRelationship(this.boardId);
      try {
        const response = await api.createRule(resource);
        this.rule = response.data.data;
      } catch (error) {
        this.$store.commit("ADD_ERRORS", error.response.data.errors);
      }
    },
    deleteRule: async function () {
      if (!this.rule) return;
      try {
        await api.deleteRule(this.rule.id);
        this.rule = undefined;
      } catch (error) {
        this.$store.commit("ADD_ERRORS", error.response.data.errors);
      }
    },
    clearForm: function () {
      this.selectedField = "timeOfDay";
      this.selectedOperator = "";
      this.value = undefined;
    },
  },
};
</script>
