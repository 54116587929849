<template>
  <BaseModal :remove-disabled="boardIsActive" @remove="remove" @save="save">
    <section>
      <h5>{{ t("Board title") }}</h5>
      <TextInputFloatingLabel for="title" :text="t('Title')">
        <input
          type="text"
          id="title"
          name="title"
          :placeholder="t('default')"
          :value="board.attributes.title"
        />
      </TextInputFloatingLabel>
    </section>
    <section>
      <h5>{{ t("Background image") }}</h5>
      <BoardSettingsBackgroundImage :board="boards[boardId]" />
    </section>
    <template v-if="multipleBoardsEnabled">
      <hr />
      <section>
        <h5>{{ t("rules_heading") }}</h5>
        <p>
          {{
            t(
              "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute."
            )
          }}
        </p>
        <p v-if="board.attributes.isDefault">
          {{
            t(
              "This is the default board. You cannot define rules for this. It is always displayed when no rules apply."
            )
          }}
        </p>
        <BoardSettingsRuleBlock v-else :board-id="boardId" />
      </section>
    </template>
    <hr />
    <section v-if="boardIsActive">
      <h5>{{ t("hint") | capitalize }}</h5>
      <p class="text--info">
        {{
          t(
            "You can't delete this board because it is currently active. Please activate another board first."
          )
        }}
      </p>
    </section>
  </BaseModal>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import BaseModal from "@/components/base/BaseModal";

import { mapState, mapGetters, mapActions } from "vuex";
import { BoardResource } from "@/api/models";
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

import BoardSettingsRuleBlock from "./rules/BoardSettingsRuleBlock";
import BoardSettingsBackgroundImage from "./BoardSettingsBackgroundImage";

/**
 Gotchas: Since this component is loaded in a Vuedals modal, props are nested inside a props sub-property.
 */
export default {
  name: "BoardSettings",
  mixins: [clickaway],
  props: {
    boardId: {
      type: String,
      required: true,
    },
  },
  components: {
    BoardSettingsBackgroundImage,
    BoardSettingsRuleBlock,
    BaseModal,
    TextInputFloatingLabel,
  },

  computed: {
    ...mapState(["boards"]),
    ...mapGetters(["activeBoardId", "multipleBoardsEnabled"]),
    board: function () {
      return this.boards[this.boardId];
    },
    boardIsActive: function () {
      return this.boardId === this.activeBoardId;
    },
  },
  methods: {
    ...mapActions(["updateBoard", "deleteBoard"]),

    save: function (form) {
      const fd = new FormData(form);
      let resource = new BoardResource({ id: this.boardId });
      for (let [key, val] of fd) {
        if (key === "upload[file]") {
          //FIXME: Special casing the file upload
          continue;
        }
        resource.setAttribute(key, val);
      }
      this.updateBoard(resource);
    },
    remove: function () {
      if (this.boardId === this.activeBoardId) {
        return;
      }

      this.deleteBoard(this.boardId);
    },
  },
  locales: {
    enGb: {
      rules_heading: "Automated display rules",
      active_rules_heading: "Show this board …",
      timeOfDay: "time of day",
      dateAndTime: "For a specified period of time",
      premium_feature_notice: "requires a license",
      after: "after",
      before: "before",
      between: "between",
      betweenDates: "between",
      hour: "hour",
      clock_label: "o'clock",
      operator_select_label: "choose",
    },
    deDe: {
      rules_heading: "Regeln für automatische Anzeige",
      active_rules_heading: "Zeige dieses Board …",
      timeOfDay: "Tageszeit",
      dateAndTime: "Für einen bestimmten Zeitraum",
      premium_feature_notice: "Lizenz benötigt",
      after: "nach",
      before: "vor",
      between: "zwischen",
      betweenDates: "zwischen",
      hour: "Stunde",
      clock_label: "Uhr",
      operator_select_label: "auswählen",
      "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute.":
        "Regeln erlauben es dir, Bedingungen anzugeben, unter denen dieses Board automatisch aktiviert werden soll. Regeln werden einmal pro Minute evaluiert.",
      "This is the default board. You cannot define rules for this. It is always displayed when no rules apply.":
        "Dies ist das Default-Board. Hierfür kannst du keine Regeln definieren. Es wird immer dann angezeigt, wenn keine Regeln greifen.",
      "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation.":
        "Board-Regeln werden ignoriert, solange die Einstellung der Board-Rotation aktiviert sind, da sie sich gegenseitig ausschließen. Um die Regelauswertung wieder zu aktivieren, schalten Sie bitte die Board-Rotation aus.",
    },
    frFr: {
      rules_heading: "Règles d'affichage automatisé",
      active_rules_heading: "Montrez ce tableau ...",
      board_scheduling_heading: "",
      timeOfDay: "moment de la journée",
      dateAndTime: "Pour une période de temps spécifiée",
      premium_feature_notice: "nécessite une licence",
      after: "après",
      before: "avant",
      between: "entre",
      betweenDates: "entre",
      hour: "heure",
      clock_label: "heure",
      operator_select_label: "choisissez",
      "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation.":
        "Les règles du conseil d'administration sont ignorées lorsque le paramètre de rotation du conseil est activé, car elles s'excluent mutuellement. Pour réactiver l'évaluation des règles, veuillez désactiver la rotation du conseil d'administration.",
      "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute.":
        "Les règles vous permettent de préciser les conditions dans lesquelles ce conseil doit être automatiquement activé. Les règles sont évaluées une fois par minute.",
      "This is the default board. You cannot define rules for this. It is always displayed when no rules apply.":
        "C'est le tableau par défaut. Vous ne pouvez pas définir de règles à cet effet. Il est toujours affiché lorsqu'aucune règle ne s'applique.",
    },
    esEs: {
      rules_heading: "Reglas de visualización automatizadas",
      active_rules_heading: "Muestra esta pizarra...",
      timeOfDay: "hora del día",
      dateAndTime: "Por un período de tiempo específico",
      premium_feature_notice: "requiere una licencia",
      after: "después de",
      before: "antes de",
      between: "entre",
      betweenDates: "entre",
      hour: "hora",
      clock_label: "en punto",
      operator_select_label: "elige",
      "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation.":
        "Las reglas del tablero se ignoran mientras se activa el ajuste de rotación del tablero, porque son mutuamente excluyentes. Para volver a habilitar la evaluación de reglas, por favor, apague la rotación del tablero.",
      "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute.":
        "Las reglas le permiten especificar las condiciones en las que este tablero debe ser activado automáticamente. Las reglas se evalúan una vez por minuto.",
      "This is the default board. You cannot define rules for this. It is always displayed when no rules apply.":
        "Este es el tablero por defecto. No se pueden definir reglas para esto. Siempre se muestra cuando no hay reglas.",
    },
    plPl: {
      rules_heading: "Zautomatyzowane zasady wyświetlania",
      active_rules_heading: "Pokaż tę tablicę...",
      timeOfDay: "pora dnia",
      dateAndTime: "Przez określony czas",
      premium_feature_notice: "wymaga licencji",
      after: "po",
      before: "przed",
      between: "pomiędzy",
      betweenDates: "pomiędzy",
      hour: "Godzina",
      clock_label: "godzina",
      operator_select_label: "wybierz",
      "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation.":
        "Zasady tablicy są ignorowane przy włączonym ustawieniu rotacji tablicy, ponieważ wzajemnie się wykluczają. Aby ponownie włączyć ocenę reguł, należy wyłączyć rotację zarządu.",
      "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute.":
        "Reguły pozwalają na określenie warunków, na jakich ta tablica powinna być automatycznie aktywowana. Reguły są oceniane raz na minutę.",
      "This is the default board. You cannot define rules for this. It is always displayed when no rules apply.":
        "To jest domyślna tablica. Nie można zdefiniować zasad w tym zakresie. Jest on zawsze wyświetlany, gdy nie obowiązują żadne reguły.",
    },
    koKr: {
      rules_heading: "자동화 된 표시 규칙",
      active_rules_heading: "이 보드 표시…",
      timeOfDay: "시간",
      dateAndTime: "지정된 기간 동안",
      premium_feature_notice: "라이센스가 필요합니다",
      after: "후",
      before: "전에",
      between: "중에서",
      betweenDates: "중에서",
      hour: "시",
      clock_label: "시",
      operator_select_label: "고르다",
      "Board rules are ignored while the board rotation setting is enabled, because they are mutually exclusive. To re-enable rule evaluation, please turn off the board rotation.":
        "보드 규칙은 상호 배타적이므로 보드 회전 설정을 사용하는 동안 무시됩니다. 규칙 평가를 다시 활성화하려면 보드 회전을 끄십시오.",
      "Rules allow you to specify the conditions under which this board should be automatically activated. Rules are evaluated once per minute.":
        "규칙을 사용하면이 보드가 자동으로 활성화되는 조건을 지정할 수 있습니다. 규칙은 분당 한 번 평가됩니다.",
      "This is the default board. You cannot define rules for this. It is always displayed when no rules apply.":
        "이것이 기본 보드입니다. 이에 대한 규칙을 정의 할 수 없습니다. 적용되는 규칙이 없을 때 항상 표시됩니다.",
    },
  },
};
</script>
<style lang="scss">
.rule-form__input {
  width: 100%;
}

.section--divider {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>
