<template>
  <nav class="widgetlist__wrapper" :class="{ scrolled: pageScrolled.bottom }">
    <button
      type="button"
      @click="scroll('right')"
      :class="[
        'widgetlist__scrollbutton',
        'text--gray',
        overflowLeft ? 'shadow' : null,
      ]"
    >
      <SvgIcon icon-name="arrow-back" />
    </button>
    <div class="widgetlist__inner" @scroll="updateScrollState">
      <WidgetListItem
        v-for="(widget, id) in activeWidgets"
        :key="id"
        :widget="widget"
        :language="language"
        :classes="gridLocked ? 'locked' : null"
      />
    </div>
    <button
      type="button"
      @click="scroll('left')"
      :class="[
        'widgetlist__scrollbutton',
        'text--gray',
        overflowRight ? 'shadow' : null,
      ]"
    >
      <SvgIcon icon-name="arrow-back" style="transform: rotate(180deg)" />
    </button>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import WidgetListItem from "@/components/WidgetListItem.vue";

export default {
  name: "WidgetList",
  components: {
    WidgetListItem,
  },
  data: function () {
    return {
      overflowLeft: false,
      overflowRight: true,
    };
  },
  computed: {
    ...mapState(["gridLocked", "pageScrolled"]),
    ...mapGetters(["activeWidgets", "language"]),
  },
  methods: {
    scroll: function (direction) {
      // TODO: Remove jQuery dependency once gridstack does not require it anymore
      const container = $(".widgetlist__inner");
      const widgetWidthInPx =
        $(".widgetlist__inner .grid-stack-item").width() + 16;
      const scrollState = container.scrollLeft();
      switch (direction) {
        case "left":
          container.animate(
            { scrollLeft: scrollState + widgetWidthInPx * 2 },
            500
          );
          break;
        case "right":
          container.animate(
            { scrollLeft: scrollState - widgetWidthInPx * 2 },
            500
          );
          break;
        default:
          break;
      }
    },
    updateScrollState: function () {
      const container = document.getElementsByClassName("widgetlist__inner")[0];
      this.overflowLeft = container.scrollLeft > 0;
      this.overflowRight =
        container.scrollLeft + container.clientWidth < container.scrollWidth;
    },
  },
};
</script>

<style lang="scss">
.widgetlist__wrapper {
  background-color: $white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  min-height: 4.75rem;
  align-self: flex-start;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 -5px 5px -4px rgba(0, 0, 0, 0.1);

  &.scrolled {
    box-shadow: none;
  }
}

::-webkit-scrollbar {
  display: none;
}

.widgetlist__scrollbutton {
  font-size: 2rem;
  width: 15%;
  max-width: 3rem;
  padding: 0 0.5rem;
  text-align: center;
  z-index: 1;
  &:first-of-type.shadow {
    box-shadow: 4px 0 6px -2px rgba(0, 0, 0, 0.1);
  }
  &:last-of-type.shadow {
    box-shadow: -4px 0 6px -2px rgba(0, 0, 0, 0.1);
  }
}

.widgetlist__inner {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
  list-style-type: none;
  > .grid-stack-item {
    margin-right: 1rem;
  }
}
</style>
