<template>
  <TextInputFloatingLabel
    for="personal_productkey"
    :text="t('product license key')"
    :hint="hint"
  >
    <input
      @change="emitFormUpdate"
      name="personal_productkey"
      id="personal_productkey"
      type="text"
      pattern="^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$"
      @input="validateProductKey"
      ref="pk"
      :value="personal_productkey.value"
      :placeholder="t('enter product key to register this installation')"
    />
  </TextInputFloatingLabel>
</template>
<script>
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";

export default {
  name: "ProductKeyInputBlock",
  components: {
    TextInputFloatingLabel,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    personal_productkey: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hint() {
      return `${this.$translate.text(
        "Don't have a product key yet?"
      )} <a href="https://glancr.de/buy-license" target="_blank">${this.$translate.text(
        "Head over to our website to learn more"
      )}</a>`;
    },
  },
  mounted: function () {
    window.setTimeout(() => {
      this.$emit(
        "key-validated",
        this.$refs.pk.validity.patternMismatch === false &&
          this.$refs.pk.value.length > 0
      );
    }, 50);
  },
  methods: {
    validateProductKey: function (e) {
      this.$emit(
        "key-validated",
        e.target.validity.patternMismatch === false && e.target.value.length > 0
      );
    },
    emitFormUpdate: function (e) {
      if (e.target.validity.patternMismatch) return;
      this.$emit("formupdate", e);
    },
  },
  // FIXME: Move these to poeditor.com once we have a OSS-approved account
  locales: {
    deDe: {
      "product license key": "Lizenzschlüssel",
      "Don't have a product key yet?": "Du hast noch keinen Lizenzschlüssel?",
      "Head over to our website to learn more":
        "Erfahre mehr auf unserer Website",
      "enter product key to register this installation":
        "Lizenzschlüssel eingeben, um diese Installation zu registrieren",
    },
    frFr: {
      "product license key": "clé de licence du produit",
      "Don't have a product key yet?":
        "Vous n'avez pas encore de clé de produit?",
      "Head over to our website to learn more":
        "Rendez-vous sur notre site web pour en savoir plus",
      "enter product key to register this installation":
        "entrez la clé du produit pour enregistrer cette installation",
    },
    esEs: {
      "product license key": "clave de licencia del producto",
      "Don't have a product key yet?":
        "¿Todavía no tienes la clave del producto?",
      "Head over to our website to learn more":
        "Dirígete a nuestra página web para saber más",
      "enter product key to register this installation":
        "introduzca la clave del producto para registrar esta instalación",
    },
    plPl: {
      "product license key": "klucz licencyjny produktu",
      "Don't have a product key yet?": "Nie masz jeszcze klucza produktu?",
      "Head over to our website to learn more":
        "Przejdź do naszej strony internetowej, aby dowiedzieć się więcej",
      "enter product key to register this installation":
        "Wprowadź klucz produktu, aby zarejestrować tę instalację",
    },
    koKr: {
      "product license key": "제품 라이센스 키",
      "Don't have a product key yet?": "아직 제품 키가 없습니까?",
      "Head over to our website to learn more":
        "자세한 내용은 웹 사이트를 방문하십시오.",
      "enter product key to register this installation":
        "이 설치를 등록하려면 제품 키를 입력하십시오",
    },
  },
};
</script>
<style scoped>
#personal_productkey:invalid {
  color: red;
}
#personal_productkey:valid {
  color: green;
}
</style>
