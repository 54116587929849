<template>
  <main class="container-padding">
    <section>
      <h5>{{ t("network settings") }}</h5>
      <form id="network_settings">
        <WLANSelectionBlock
          :network_ssid="settings.network_ssid.attributes"
          :network_password="settings.network_password.attributes"
          :network_connectiontype="settings.network_connectiontype.attributes"
          :network_ssidinvisible="settings.network_ssidinvisible.attributes"
          @formupdate="handleSettingUpdate"
          :system-status="systemStatus"
        />

        <button
          class="button small button--shadow"
          :disabled="!formDirty['network_settings']"
          type="submit"
          @click.prevent="connectNetwork"
        >
          <AnimatedLoader v-if="this.working['network_settings']" />
          <template v-else>
            {{ t("connect") }}
          </template>
        </button>
        <span v-if="this.successState['network_settings'] != null">
          {{ this.successState["network_settings"] ? "✓" : "X" }}
        </span>
      </form>

      <h5>{{ t("system settings") }}</h5>
      <form id="system_settings" @submit.prevent>
        <LanguageSelectionBlock
          :system_language="settings.system_language.attributes"
          @formupdate="handleSettingUpdate"
        />
        <TimezoneSelectionBlock
          :system_timezone="settings.system_timezone.attributes"
          @formupdate="handleSettingUpdate"
        />
        <RadioToggleBorderedLabel
          field-id="system_multipleboards"
          :text="t('multiple boards')"
          :active="settings.system_multipleboards.attributes.value === 'on'"
          @formupdate="handleSettingUpdate"
        />
        <TextInputFloatingLabel
          for="system_backgroundcolor"
          :text="t('Background color')"
        >
          <input
            type="color"
            placeholder="#000000"
            id="system_backgroundcolor"
            name="system_backgroundcolor"
            @change="handleSettingUpdate"
            list="default-colors"
            :value="settings.system_backgroundcolor.attributes.value"
          />
        </TextInputFloatingLabel>
        <TextInputFloatingLabel for="system_fontcolor" :text="t('Font color')">
          <input
            type="color"
            placeholder="#ffffff"
            id="system_fontcolor"
            name="system_fontcolor"
            @change="handleSettingUpdate"
            list="default-colors"
            :value="settings.system_fontcolor.attributes.value"
          />
          <datalist id="default-colors">
            <option value="#ffffff">
              {{ t("white") }}
            </option>
            <option value="#000000">
              {{ t("black") }}
            </option>
          </datalist>
        </TextInputFloatingLabel>

        <template v-if="!enablePremiumFeatures">
          <h5>
            {{ t("Unlock advanced features") }}
          </h5>
          <p>
            {{
              t(
                "Get these advanced features and support future development of mirr.OS!"
              )
            }}
            <a @click="scrollToLicenseField">{{
              t("Enter your license key down below to unlock.")
            }}</a>
          </p>
        </template>
        <fieldset name="premium-features" :disabled="!enablePremiumFeatures">
          <RadioToggleBorderedLabel
            field-id="system_showerrornotifications"
            :text="t('Show error notifications on display')"
            :active="
              settings.system_showerrornotifications.attributes.value === 'on'
            "
            @formupdate="handleSettingUpdate"
          />
          <TextInputFloatingLabel
            for="system_themecolor"
            :text="t('Theme color')"
          >
            <input
              type="color"
              placeholder="#8ba4c1"
              id="system_themecolor"
              name="system_themecolor"
              @change="handleSettingUpdate"
              list="default-theme-colors"
              :value="settings.system_themecolor.attributes.value"
            />
            <datalist id="default-theme-colors">
              <option value="#8ba4c1">
                {{ t("glancr blue") }}
              </option>
            </datalist>
          </TextInputFloatingLabel>
          <RadioToggleBorderedLabel
            field-id="system_boardrotation"
            :text="t('Rotate boards at interval')"
            :active="settings.system_boardrotation.attributes.value === 'on'"
            @formupdate="handleSettingUpdate"
          />
          <RangeInputLabel
            v-if="settings.system_boardrotation.attributes.value === 'on'"
            :label="t('rotation interval in minutes')"
            name="system_boardrotationinterval"
            :min="1"
            :max="120"
            :value="rotationInterval"
            @change="handleSettingUpdate"
          />
          <select
            id="system_displayfont"
            name="system_displayfont"
            @change="handleSettingUpdate"
          >
            <option disabled selected value="">
              {{ t("Select your preferred display font") }}
            </option>
            <option
              v-for="(label, option) in settings.system_displayfont.attributes
                .options"
              :key="option"
              :value="option"
              :selected="
                option === settings.system_displayfont.attributes.value
              "
            >
              {{ t("Font") }}: {{ label }}
            </option>
          </select>

          <RadioToggleBorderedLabel
            field-id="system_passwordprotection"
            :text="t('Require a password for mirr.OS settings')"
            :active="
              settings.system_passwordprotection.attributes.value === 'on'
            "
            @formupdate="handleSettingUpdate"
          />
          <div
            class="input-group"
            v-if="settings.system_passwordprotection.attributes.value === 'on'"
          >
            <TextInputFloatingLabel
              for="system_passwordprotection"
              :text="t('Password')"
            >
              <input
                type="text"
                class="input-group-field"
                placeholder="a-great-password"
                id="system_adminpassword"
                name="system_adminpassword"
                @change="cipherPasswordAndUpdate"
                :value="rot13(settings.system_adminpassword.attributes.value)"
              />
            </TextInputFloatingLabel>
            <div class="input-group-button">
              <button type="button" class="button small" style="color: white">
                <SvgIcon icon-name="activate" class="icon--label" />
              </button>
            </div>
          </div>
          <div class="input-group">
            <TextInputFloatingLabel
              for="system_scheduleshutdown"
              :text="t('Shut down mirr.OS each day at')"
            >
              <input
                type="time"
                name="system_scheduleshutdown"
                class="input-group-field"
                id="system_scheduleshutdown"
                :placeholder="t('hh:mm (24-hour style)')"
                :value="settings.system_scheduleshutdown.attributes.value"
                @change="handleSettingUpdate"
              />
            </TextInputFloatingLabel>
            <div class="input-group-button">
              <button
                type="button"
                class="button small"
                style="color: white"
                @click="updateSetting('system_scheduleshutdown', '')"
              >
                {{ t("clear schedule") }}
              </button>
            </div>
          </div>
          <p>
            {{
              t(
                "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button."
              )
            }}
          </p>
        </fieldset>
      </form>
      <p class="notice">
        {{ t("Note: You can set a background image in the board settings.") }}
      </p>

      <h5>{{ t("personal settings") }}</h5>
      <form id="personal_settings">
        <ProductKeyInputBlock
          :personal_productkey="settings.personal_productkey.attributes"
          @formupdate="handleSettingUpdate"
          @key-validated="handleProductKeyValidation"
        />
        <PersonalSettingsBlock
          :personal_name="settings.personal_name.attributes"
          :personal_email="settings.personal_email.attributes"
          @formupdate="handleSettingUpdate"
        />

        <button
          class="button small button--shadow"
          :disabled="!formDirty['personal_settings']"
          type="submit"
          @click.prevent="sendTestEmail"
        >
          <template v-if="this.successState['personal_settings'] != null">
            {{
              this.successState["personal_settings"]
                ? t("check your mails") + " ✓"
                : t("error") + " ✗"
            }}
          </template>
          <template v-else>
            <AnimatedLoader
              v-if="this.working['personal_settings']"
              class="svg--inline"
            />
            <template v-else>
              {{ t("send test email") }}
            </template>
          </template>
        </button>
      </form>
      <h5>{{ t("Export settings") }}</h5>
      <a
        :href="`${$root.$options.backendUrl}/system/backup`"
        target="_blank"
        class="button"
        >{{ t("download backup") }}</a
      >
    </section>
  </main>
</template>

<script>
import ChecksPassword from "@/mixins/ChecksPassword";

import { mapState } from "vuex";
import { SettingResource } from "@/api/models";

import WLANSelectionBlock from "@/components/config/WLANSelectionBlock.vue";
import LanguageSelectionBlock from "@/components/config/LanguageSelectionBlock.vue";
import PersonalSettingsBlock from "@/components/config/PersonalSettingsBlock.vue";
import TimezoneSelectionBlock from "@/components/config/TimezoneSelectionBlock.vue";
import ProductKeyInputBlock from "@/components/config/ProductKeyInputBlock.vue";
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel.vue";
import RadioToggleBorderedLabel from "@/components/forms/RadioToggleBorderedLabel.vue";
import RangeInputLabel from "@/components/forms/RangeInputLabel.vue";

import AnimatedLoader from "@/components/AnimatedLoader.vue";

export default {
  name: "PageSettings",
  mixins: [ChecksPassword],
  components: {
    AnimatedLoader,
    WLANSelectionBlock,
    LanguageSelectionBlock,
    PersonalSettingsBlock,
    TimezoneSelectionBlock,
    ProductKeyInputBlock,
    TextInputFloatingLabel,
    RadioToggleBorderedLabel,
    RangeInputLabel,
  },
  data: () => {
    return {
      formDirty: {
        network_settings: false,
        personal_settings: false,
        system_settings: false,
      },
      working: {
        network_settings: false,
        personal_settings: false,
        system_settings: false,
      },
      successState: {
        network_settings: null,
        personal_settings: null,
        system_settings: null,
      },
      enablePremiumFeatures: false,
    };
  },
  computed: {
    ...mapState(["settings", "systemStatus"]),
    rotationInterval: function () {
      return this.settings.system_boardrotationinterval.attributes.value.slice(
        0,
        -1
      );
    },
  },
  created: async function () {
    await this.$store.dispatch("fetchSettings");
  },
  methods: {
    /**
     * Transforms the clear-text password and updates the store to keep the
     * user logged in. For developers: This is not meant as a weak “crypto”,
     * of course! Until we have time to implement a proper auth at the
     * API level, this will at least keep out non-technical users.
     *
     * @param {InputEvent} e The form element input event
     */
    cipherPasswordAndUpdate: function (e) {
      // Avoid user “logout” after updating
      this.updatePasswordMatchStatus(true);
      this.updateSetting(e.target.name, this.rot13(e.target.value));
    },
    handleSettingUpdate: function (e) {
      this.formDirty[e.target.form.id] = true;
      this.updateSetting(e.target.name, e.target.value);
    },
    updateSetting: function (setting, value) {
      if (setting === "system_passwordprotection") {
        this.updatePasswordMatchStatus(true); // Avoid user “logout” after updating
      }
      let res = new SettingResource(setting);
      // rotation interval is saved as a duration string
      res.attributes.value =
        setting === "system_boardrotationinterval" ? `${value}m` : value;
      this.$store.dispatch("updateSetting", { resource: res });
    },
    applySetting: async function (formId, endpoint) {
      this.formDirty[formId] = false;
      this.working[formId] = true;
      try {
        const res = await this.axios.get(endpoint);
        this.successState[formId] = res.data.success;
      } catch (error) {
        this.successState[formId] = false;
      } finally {
        this.working[formId] = false;
        window.setTimeout(() => {
          this.successState[formId] = null;
        }, 5000);
      }
    },

    connectNetwork: function () {
      if (this.settings.network_connectiontype.attributes.value === "wlan") {
        this.applySetting(
          "network_settings",
          "/system/control/network/connect"
        );
      } else {
        // TODO: Validate LAN state with backend
        this.applySetting(
          "network_settings",
          "/system/control/network/enable_lan"
        );
        this.successState["network_settings"] = true;
        setTimeout(() => {
          this.successState["network_settings"] = null;
        }, 5000);
      }
    },

    sendTestEmail: function () {
      this.applySetting(
        "personal_settings",
        "/system/control/personal/send_setup_email"
      );
    },

    handleProductKeyValidation: function (isValid) {
      this.enablePremiumFeatures = isValid;
      this.$store.commit("CHANGE_PREMIUM_FEATURES_ENABLED", isValid);
    },

    scrollToLicenseField: function () {
      const productKeyField = document.getElementById("personal_productkey");
      const position = productKeyField?.getBoundingClientRect();

      productKeyField.style.backgroundColor = "rgba(255, 255, 0, 0.3)";
      window.setTimeout(function () {
        productKeyField.style.backgroundColor = "";
      }, 5000);

      document.querySelector("#app main").scrollTo({
        top: position.top,
        left: position.left,
        behavior: "smooth",
      });
    },
  },
  // FIXME: Move these to poeditor.com once we have a OSS-approved account
  locales: {
    deDe: {
      "Unlock advanced features": "Erweiterte Einstellungen freischalten",
      "Get these advanced features and support future development of mirr.OS!":
        "Erhalte diese Profi-Funktionen und unterstütze die Weiterentwicklung von mirr.OS!",
      "Enter your license key down below to unlock.":
        "Gib zum Freischalten unten deinen Lizenzschlüssel ein.",
      "Show error notifications on display": "Fehler auf dem Screen anzeigen",
      "Rotate boards at interval": "Boards in Intervallen rotieren lassen",
      "rotation interval in minutes": "Rotationsintervall in Minuten",
      "Select your preferred display font":
        "Wähle deine bevorzugte Schriftart für das Board",
      Font: "Schriftart",
      "Note: You can set a background image in the board settings.":
        "Hinweis: Hintergrundbilder werden jetzt in den Einstellungen des jeweiligen Boards hinterlegt.",
      "Require a password for mirr.OS settings":
        "Passwortschutz für die mirr.OS-Einstellungen",
      "Shut down mirr.OS each day at": "mirr.OS jeden Tag herunter fahren um",
      "hh:mm (24-hour style)": "hh:mm (24-Stunden Format)",
      "clear schedule": "Zeitplan entfernen",
      "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button.":
        "Wenn diese Einstellung konfiguriert ist, wird mirr.OS jeden Tag zur vorgegebenen Zeit automatisch heruntergefahren - nützlich für automatisierte Szenarien. Um mirr.OS 24/7 laufen zu lassen, entfernen Sie einfach den Zeitplan über die Schaltfläche.",
    },
    frFr: {
      "Unlock advanced features": "Déverrouiller les fonctionnalités avancées",
      "Get these advanced features and support future development of mirr.OS!":
        "Profitez de ces fonctionnalités avancées et soutenez le développement futur de mirr.OS!",
      "Enter your license key down below to unlock.":
        "Entrez votre clé de licence ci-dessous pour déverrouiller.",
      "Show error notifications on display":
        "Afficher les notifications d'erreur",
      "Rotate boards at interval": "Conseils de soudage à intervalles",
      "rotation interval in minutes": "intervalle de rotation en minutes",
      "Select your preferred display font":
        "Sélectionnez votre police d'affichage préférée",
      Font: "Police",
      "Note: You can set a background image in the board settings.":
        "Note : Vous pouvez définir une image de fond dans les paramètres du forum.",
      "Require a password for mirr.OS settings":
        "Nécessite un mot de passe pour les paramètres de mirr.OS",
      "Shut down mirr.OS each day at": "Fermez mirr.OS chaque jour à",
      "hh:mm (24-hour style)": "hh:mm (Style 24 heures)",
      "clear schedule": "un calendrier clair",
      "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button.":
        "Une fois configuré, mirr.OS s'arrêtera automatiquement chaque jour à l'heure donnée - utile pour les scénarios automatisés. Pour permettre à mirr.OS de fonctionner 24 heures sur 24, 7 jours sur 7, il suffit de supprimer l'horaire via le bouton.",
    },
    esEs: {
      "Unlock advanced features": "desbloquear las funciones avanzadas",
      "Get these advanced features and support future development of mirr.OS!":
        "Utiliza estas características avanzadas y apoya el futuro desarrollo de mirr.OS!",
      "Enter your license key down below to unlock.":
        "Introduce tu clave de licencia abajo para desbloquear.",
      "Show error notifications on display":
        "Mostrar notificaciones de error en la pantalla",
      "Rotate boards at interval":
        "Tablas de soldadura en soldadura rotativa a intervalos",
      "rotation interval in minutes": "intervalo de rotación en minutos",
      "Select your preferred display font":
        "Seleccione su fuente de visualización preferida",
      Font: "Fuente",
      "Note: You can set a background image in the board settings.":
        "Nota: Puede establecer una imagen de fondo en la configuración del tablero.",
      "Require a password for mirr.OS settings":
        "Requiere una contraseña para la configuración de mirr.OS",
      "Shut down mirr.OS each day at":
        "Apagar el sistema operativo espejo cada día en",
      "hh:mm (24-hour style)": "hh:mm (Estilo de 24 horas)",
      "clear schedule": "un programa claro",
      "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button.":
        "Cuando se configura, el sistema operativo espejo se apagará automáticamente cada día a la hora indicada, lo que es útil para los escenarios automatizados. Para dejar que mirr.OS funcione 24/7, sólo hay que quitar el programa a través del botón.",
    },
    plPl: {
      "Unlock advanced features": "Odblokuj zaawansowane funkcje",
      "Get these advanced features and support future development of mirr.OS!":
        "Pobierz te zaawansowane funkcje i wspieraj przyszły rozwój mirr.OS!",
      "Enter your license key down below to unlock.":
        "Wpisz poniżej swój klucz licencyjny, aby odblokować.",
      "Show error notifications on display":
        "Wyświetlanie powiadomień o błędach na wyświetlaczu",
      "Select your preferred display font":
        "Wybierz preferowaną czcionkę wyświetlacza",
      Font: "Czcionka",
      "Note: You can set a background image in the board settings.":
        "Uwaga: W ustawieniach płyty można ustawić obraz tła.",
      "Require a password for mirr.OS settings":
        "Wymagaj hasła do ustawień mirr.OS",
      "Shut down mirr.OS each day at": "Wyłączać mirr.OS każdego dnia na",
      "hh:mm (24-hour style)": "hh:mm (Styl 24-godzinny)",
      "clear schedule": "przejrzysty harmonogram",
      "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button.":
        "Po skonfigurowaniu system mirr.OS będzie wyłączał się automatycznie każdego dnia o danej porze - przydatne przy automatycznych scenariuszach. Aby umożliwić działanie mirr.OS w trybie 24/7, wystarczy usunąć harmonogram za pomocą przycisku.",
    },
    koKr: {
      "Unlock advanced features": "고급 기능 잠금 해제",
      "Get these advanced features and support future development of mirr.OS!":
        "이러한 고급 기능을 사용하고 향후 mirr.OS 개발을 지원하십시오!",
      "Enter your license key down below to unlock.":
        "잠금을 해제하려면 아래에 라이센스 키를 입력하십시오.",
      "Show error notifications on display": "디스플레이에 오류 알림 표시",
      "Rotate boards at interval": "보드가 일정한 간격으로 회전하게하십시오",
      "rotation interval in minutes": "분 단위의 회전 간격",
      "Select your preferred display font": "원하는 표시 글꼴을 선택하십시오",
      Font: "폰트",
      "Note: You can set a background image in the board settings.":
        "참고 : 보드 설정에서 배경 이미지를 설정할 수 있습니다.",
      "Require a password for mirr.OS settings":
        "mirr.OS 설정을위한 비밀번호 필요",
      "Shut down mirr.OS each day at": "매일 mirr.OS를 종료합니다.",
      "hh:mm (24-hour style)": "hh:mm (24 시간 스타일)",
      "clear schedule": "명확한 일정",
      "When configured, mirr.OS will shut down automatically each day at the given time – useful for automated scenarios. To let mirr.OS run 24/7, just remove the schedule via the button.":
        "구성된 경우 mirr.OS는 매일 지정된 시간에 자동으로 종료되므로 자동화 된 시나리오에 유용합니다. mirr.OS가 연중 무휴로 실행되도록하려면 버튼을 통해 일정을 제거하기 만하면됩니다.",
    },
  },
};
</script>

<style lang="scss">
.notice {
  padding: 1.125rem;
  background-color: $light-gray;
}

.setting legend {
  font-size: 1.5rem;
}

fieldset[disabled] {
  opacity: 0.35;
  > * {
    background-color: $light-gray;
  }
}
</style>
