<template>
  <div>
    <section>
      <fieldset>
        <RadioBoxedLabel
          :labelleft="{
            for: 'network_connectiontype_option_wlan',
            text: t('WiFi'),
          }"
          :labelright="{
            for: 'network_connectiontype_option_lan',
            text: t('Wired'),
          }"
          :hideinput="true"
          :selected="network_connectiontype.value === 'wlan' ? 'left' : 'right'"
        >
          <template #icon-left>
            <SvgIcon icon-name="wifi" class="custom-radio__icon" />
          </template>
          <template #input-left>
            <input
              type="radio"
              name="network_connectiontype"
              id="network_connectiontype_option_wlan"
              value="wlan"
              :checked="network_connectiontype.value === 'wlan'"
              @change="emitFormUpdate"
              required
            />
          </template>
          <template #icon-right>
            <SvgIcon icon-name="lan" class="custom-radio__icon" />
          </template>
          <template #input-right>
            <input
              type="radio"
              name="network_connectiontype"
              id="network_connectiontype_option_lan"
              value="lan"
              :checked="network_connectiontype.value === 'lan'"
              @change="emitFormUpdate"
              required
            />
          </template>
        </RadioBoxedLabel>
      </fieldset>
    </section>

    <section v-if="network_connectiontype.value.match(/wlan|^\s*$/) != null">
      <fieldset>
        <CheckboxBorderedLabel
          for="network_ssidinvisible"
          :text="t('Invisible network')"
        >
          <input
            type="checkbox"
            id="network_ssidinvisible"
            name="network_ssidinvisible"
            :value="network_ssidinvisible.value"
            true-value="yes"
            false-value="no"
            :checked="network_ssidinvisible.value === 'yes'"
            @change="emitFormUpdate"
          />
        </CheckboxBorderedLabel>

        <div v-if="network_ssidinvisible.value === 'no'">
          <label for="network_ssid" style="display: none">{{
            t("Select your WiFi network")
          }}</label>
          <div class="input-group">
            <select
              class="input-group-field grayscale"
              id="network_ssid"
              name="network_ssid"
              required
              v-validate
            >
              <option disabled selected value="">
                {{ t("Select your WiFi network") }}
              </option>
              <option
                v-for="(obj, idx) in availableNetworks"
                :key="idx"
                :value="obj.ssid"
                :selected="obj.ssid === network_ssid.value"
              >
                {{ obj.ssid }} {{ obj.encryption ? "🔒" : null }} ({{
                  obj.signal
                }}%)
              </option>
            </select>
            <div class="input-group-button">
              <button
                v-if="loading"
                type="button"
                class="button small"
                disabled
              >
                <AnimatedLoader />
              </button>
              <button
                v-else
                type="button"
                class="button small"
                @click="getAvailableNetworks"
              >
                <SvgIcon icon-name="wifisync" />
              </button>
            </div>
          </div>
        </div>
        <template v-if="network_ssidinvisible.value === 'yes'">
          <TextInputFloatingLabel
            for="network_ssid"
            :text="t('Name of your WiFi')"
          >
            <input
              type="text"
              id="network_ssid"
              name="network_ssid"
              required
              v-validate
              :value="network_ssid.value"
              placeholder=" "
              autocomplete="off"
            />
          </TextInputFloatingLabel>
        </template>
        <div
          class="input-group"
          v-if="
            selectedNetworkIsEncrypted || network_ssidinvisible.value === 'yes'
          "
        >
          <TextInputFloatingLabel
            for="network_password"
            :text="t('WiFi password')"
          >
            <input
              class="input-group-field"
              id="network_password"
              name="network_password"
              placeholder=" "
              :type="passwordVisible ? 'text' : 'password'"
              required
              v-validate
              :value="network_password.value"
              autocomplete="current-password"
            />
          </TextInputFloatingLabel>

          <div class="input-group-button">
            <button
              type="button"
              class="button small"
              @click="togglePasswordFieldVisibility"
            >
              <!-- SVG#use is rendered in shadowDOM, so a prop change doesn't show there -->
              <SvgIcon
                v-show="passwordVisible"
                icon-name="eye"
                class="icon--label"
              />
              <SvgIcon
                v-show="!passwordVisible"
                icon-name="eye-crossed"
                class="icon--label"
              />
            </button>
          </div>
        </div>
      </fieldset>
    </section>
    <section v-else>
      <p class="centered">
        {{
          systemOffline
            ? t("connect your network cable now")
            : t("connected!") + " IP: " + primaryConnectionIP
        }}
      </p>
    </section>
  </div>
</template>
<script>
import TextInputFloatingLabel from "@/components/forms/TextInputFloatingLabel";
import CheckboxBorderedLabel from "@/components/forms/CheckboxBorderedLabel";
import RadioBoxedLabel from "@/components/forms/RadioBoxedLabel";

import { mapGetters } from "vuex";
import AnimatedLoader from "../AnimatedLoader.vue";

export default {
  name: "WLANSelectionBlock",
  components: {
    TextInputFloatingLabel,
    CheckboxBorderedLabel,
    AnimatedLoader,
    RadioBoxedLabel,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    network_connectiontype: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_ssidinvisible: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_ssid: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    network_password: {
      type: Object,
      required: true,
    },
    systemStatus: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      networkHidden: false,
      passwordVisible: false,
      availableNetworks: [],
      connectionType: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["systemOffline", "primaryConnectionIP"]),
    selectedNetworkIsEncrypted: function () {
      const selected = this.availableNetworks.filter(
        (wifi) => wifi.ssid === this.network_ssid.value
      );
      return selected[0] && selected[0].encryption;
    },
  },
  mounted: function () {
    this.getAvailableNetworks();
  },
  methods: {
    getAvailableNetworks: async function () {
      this.loading = true;
      try {
        const res = await this.axios.get("/system/control/network/list");
        this.availableNetworks = res.data.result;
      } catch (error) {
        this.$store.dispatch("handleError", error);
      }
      this.loading = false;
    },
    emitFormUpdate: function (e) {
      // FIXME: This is a ugly hack, since somehow inbetween DOM, Vue and vuex, the event still (or again) holds the old value. This results in submitting the default "no" over and over.
      if (e.target.name === "network_ssidinvisible") {
        e.target.value = e.target.value === "no" ? "yes" : "no";
      }
      this.$emit("formupdate", e);
    },
    toggleSSIDField: function (e) {
      this.networkHidden = !this.networkHidden;
      this.emitFormUpdate(e);
    },

    togglePasswordFieldVisibility: function () {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  locales: {
    deDe: {
      "connect your network cable now": "schliesse jetzt dein Netzwerkkabel an",
      "connected!": "verbunden!",
    },
    frFr: {
      "connect your network cable now":
        "Connectez votre câble réseau maintenant",
      "connected!": "liée!",
    },
    esEs: {
      "connect your network cable now": "Conecte su cable de red ahora",
      "connected!": "¡conectada!",
    },
    plPl: {
      "connect your network cable now": "Podłącz teraz kabel sieciowy",
      "connected!": "połączony!",
    },
    koKr: {
      "connect your network cable now": "지금 네트워크 케이블을 연결하십시오",
      "connected!": "연결되어있어!",
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group {
  .button {
    width: 2.5rem;
    padding: 0;
  }
  svg {
    width: 1.25rem;
    height: auto;
  }
}
.centered {
  text-align: center;
}
</style>
