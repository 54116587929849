export const validate = {
  bind(el, _, vnode) {
    const vm = vnode.context;
    // Uses timer because Vuex data might not be immediately present.
    window.setTimeout(() => {
      vm.$emit("input-validated", {
        component: vm.$vnode.componentOptions.tag,
        elname: el.name,
        msg: el.validationMessage,
      });
    }, 50);

    el.addEventListener("input", (e) => {
      const vm = vnode.context;
      vm.$emit("input-validated", {
        component: vm.$vnode.componentOptions.tag,
        elname: el.name,
        msg: e.target.validationMessage,
      });
    });
    el.addEventListener("change", (e) => {
      vnode.context.$emit("formupdate", e);
    });
  },
  unbind(el, _, vnode) {
    /*
     * FIXME: This might break if the element is not directly nested inside
     * one of the three validatable blocks (see Setup.vue attribute `errors`)
     */
    const component =
      vnode.componentInstance != undefined
        ? vnode.componentOptions.tag
        : vnode.context.$vnode.componentOptions.tag;
    vnode.context.$emit("clear-validations", {
      block: component,
      field: el.id,
    });
  },
};
