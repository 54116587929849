import Vue from "vue";
import Vuex from "vuex";

import * as getters from "./store/getters";
import * as actions from "./store/actions";
import * as mutations from "./store/mutations";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

if (window.location.hash.length === 0) {
  window.location.hash = "#board";
}

export default new Vuex.Store({
  state: {
    fetching: true,
    blocking: false,
    backendRestarting: false,
    errors: [],
    networkError: "",
    notifications: [],
    gridLocked: true,
    gridSize: null,
    pageScrolled: {
      top: false,
      bottom: false,
    },
    systemStatus: {},
    currentWidgetInstance: "",
    webappHintVisible: true,
    widgetInstances: {},
    widgets: {},
    sourceInstances: {},
    sources: {},
    groups: {},
    boards: {},
    loadedBoard: undefined,
    route: window.location.hash,
    settings: {},
    instanceAssociations: {},
    passwordMatches: false,
    premiumFeaturesEnabled: false,
  },
  actions,
  // @FIXME: Refactor fetching status for each call, so they don't interfere
  getters,
  mutations,
  strict: debug,
});

if (module.hot) {
  module.hot.accept(
    ["./store/getters", "./store/actions", "./store/mutations"],
    () => {
      self.hotUpdate({
        getters: require("./store/getters").default,
        actions: require("./store/actions").default,
        mutations: require("./store/mutations").default,
      });
    }
  );
}
