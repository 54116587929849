<template>
  <div
    class="grid-stack-item"
    :id="`widget-${id}`"
    :gs-id="id"
    :gs-x="config.position.x"
    :gs-y="config.position.y"
    :gs-w="config.position.width"
    :gs-h="config.position.height"
  >
    <div
      :class="[locked ? 'locked' : '', 'grid-stack-item-content']"
      @click="showSettingsModal"
    >
      <!-- TODO: Handle case where widget is only one cell wide/tall, where the double arrow is not 100% correct -->
      <div v-show="!locked" class="widget__overlay">
        <div class="widget__resize-handle widget__resize-handle--top">
          <span v-show="freeSpace.top">&#8597; </span>
          <span v-show="!freeSpace.top">&darr;</span>
        </div>
        <div class="widget__resize-handle widget__resize-handle--right">
          <span v-show="freeSpace.right">&#8596;</span
          ><span v-show="!freeSpace.right">&larr;</span>
        </div>
        <div class="widget__resize-handle widget__resize-handle--bottom">
          <span v-show="freeSpace.bottom">&#8597;</span
          ><span v-show="!freeSpace.bottom">&uarr;</span>
        </div>
        <div class="widget__resize-handle widget__resize-handle--left">
          <span v-show="freeSpace.left">&#8596;</span
          ><span v-show="!freeSpace.left">&rarr;</span>
        </div>
        <div class="widget__drag-container">
          <SvgIcon
            icon-name="move"
            class="widget__drag-handle"
            icon-color="white"
          />
        </div>
      </div>
      <img class="widget__icon" :src="widgetIconPath" />
      <h6 v-show="config.position.height > 2" class="widget__title">
        {{ localisedAttribute(baseWidget, "title", language) }}
      </h6>
    </div>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from "vuedals";
import WidgetSettings from "@/components/WidgetSettings.vue";
import { mapGetters, mapState } from "vuex";
import { api } from "@/api/operations";
import localisedAttribute from "@/mixins/localisedAttribute";

export default {
  name: "GridContainerItem",
  mixins: [localisedAttribute],
  props: {
    id: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      widgetIconPath: undefined,
    };
  },
  computed: {
    ...mapState(["gridSize"]),
    ...mapGetters(["language"]),
    widgetInstance: function () {
      return this.$store.state.widgetInstances[this.id];
    },
    config: function () {
      return this.widgetInstance.attributes;
    },
    /**
     * @returns {Widget} The base widget object for this instance.
     */
    baseWidget: function () {
      return this.$store.getters.relatedEntity(
        "widgetInstances",
        this.id,
        "widget"
      );
    },
    /**
     * @typedef {object} FreeSpace
     * @property {boolean} top    – true if there is free space between the widget and the top grid border, false otherwise.
     * @property {boolean} right  – like top, but for the right border.
     * @property {boolean} bottom – like top, but for the bottom border.
     * @property {boolean} left   – like top, but for the left border.
     */

    /**
     * Computes for each widget side if it has free space available next to it.
     * @returns {FreeSpace}
     */
    freeSpace() {
      const pos = this.config.position;
      return {
        top: pos.y > 0,
        right: pos.x + pos.width < this.gridSize.column,
        bottom: pos.y + pos.height < this.gridSize.row,
        left: pos.x > 0,
      };
    },
  },
  beforeMount() {
    this.widgetIconPath = api.getExtensionAssetPath({
      extType: this.baseWidget.type,
      extension: this.baseWidget.id,
      assetType: "icons",
      filename: `${this.baseWidget.id}.svg?version=${this.baseWidget.attributes.version}`,
    });
  },
  mounted: function () {
    this.$emit("init-gridstack-widget", this.$el);
  },
  methods: {
    showSettingsModal: function () {
      if (!this.locked) return;

      this.$store.commit(
        "CHANGE_CURRENT_WIDGET_INSTANCE",
        this.widgetInstance.id
      );

      VuedalsBus.$emit("new", {
        title: `${this.localisedAttribute(
          this.baseWidget,
          "title",
          this.language
        )}`,
        name: `settings-modal-${this.baseWidget.id}-${this.$props.id}`,
        component: WidgetSettings,

        size: window.innerWidth <= 480 ? "xs" : "md", // FIXME: Determine from global settings once established.
      });
    },
  },
};
</script>

<style lang="scss">
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.grid-stack > .grid-stack-item {
  position: absolute;
  /* 
  Calculate the equivalent of 5px padding on a 12col reference grid.
  --grid-width is set in GridContainer.vue. The production build pipeline throws away nested calc/parentheses, and we can precompute this value for all widgets.
  */
  padding: calc(5 / var(--grid-width) * 100%);
  box-sizing: border-box;
}

.grid-stack-item-content {
  background-origin: content-box;
  background-repeat: no-repeat;
  color: $black;
  height: 100%;
  box-shadow: $global-box-shadow;
  border-radius: $global-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &.locked {
    background-color: $cream;
    &:hover {
      cursor: pointer;
    }
  }
}

.widget__icon {
  max-height: 3rem;
}

.widget__title {
  margin: 0.5rem 0 0 0;
}

.widget__drag-container {
  width: 75%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: move;
  }
}

.widget__drag-handle {
  width: 100%;
  height: 100%;
  max-width: 5rem;
  max-height: 5rem;
}

.widget__resize-handle {
  font-size: $resize-handle-size * 0.5;
  line-height: $resize-handle-size * 0.5;
  position: absolute;
  color: $medium-gray;
  background-color: $cream;
  width: $resize-handle-size;
  height: $resize-handle-size;
  text-align: center;
  border-radius: 50%;
  padding: $resize-handle-size * 0.25;

  animation: pulse 2s infinite ease-in-out 0s;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  &--top {
    top: -0.5rem;
  }

  &--right {
    right: -0.5rem;
  }

  &--bottom {
    bottom: -0.5rem;
  }

  &--left {
    left: -0.5rem;
  }

  .ui-draggable-dragging & {
    display: none;
  }
}

.widget__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--theme-color-rgb), 0.9);
  border-radius: $global-radius;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay__center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
