// ############ Helper functions #################

export function camelcase(string) {
  return string
    .replace(/^[_.\- ]+/, "")
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase());
}

export function decamelize(string) {
  const regex = new RegExp(/([A-Z]{1})/g);
  return string.replace(regex, `-$1`).toLowerCase();
}

export function denormalize(resource) {
  return { data: { ...resource } };
}

/**
 * Constructs a valid JSON:API include parameter string.
 *
 * @param {string[]} includes Array of related resources to include in the response.
 * @returns {string} The final include string
 */
export function buildIncludeString(includes) {
  if (includes.length === 0) {
    return "";
  }

  return includes.reduce((acc, include, currentIndex, array) => {
    let sep = currentIndex != 0 && currentIndex != array.length ? "," : "";
    return `${acc}${sep}${include}`;
  }, "?include=");
}

export function buildFilterString(filters) {
  if (filters.length === 0) {
    return "";
  }

  return filters.reduce((acc, filter, currentIndex, array) => {
    let sep = currentIndex != 0 && currentIndex != array.length ? "&" : "";
    return `${acc}${sep}filter[${filter[0]}]=${filter[1]}`;
  }, "?");
}

/**
 * @returns {string} Path to the backend server.
 */
export function buildBackendUrl() {
  let url = new URL(`http://${window.location.hostname}/api/`);
  url.protocol = process.env.VUE_APP_BACKEND_PROTOCOL || url.protocol;
  url.hostname = process.env.VUE_APP_BACKEND_HOSTNAME || window.location.hostname;
  url.port = process.env.VUE_APP_BACKEND_PORT || url.port;
  url.pathname = process.env.VUE_APP_BACKEND_PATH || url.pathname;

  return url.toString()
}
