<template>
  <main
    class="board"
    :class="{
      [boardOrientation]: true,
      scrolled: pageScrolled.top && !multipleBoardsEnabled,
    }"
  >
    <section class="board__controls" :class="{ scrolled: pageScrolled.top }">
      <GridControls :editing-board-id="editingBoardId" />
      <BoardSelector
        v-if="multipleBoardsEnabled"
        :editing-board-id="editingBoardId"
      />
      <div v-else class="single-board-settings">
        <SingleBoardSettingsButton :board-id="activeBoardId" />
      </div>
    </section>

    <GridContainer :key="editingBoardId" :editing-board-id="editingBoardId" />

    <WidgetList />
  </main>
</template>

<script>
import WidgetList from "@/components/WidgetList.vue";
import GridContainer from "@/components/board/GridContainer.vue";
import GridControls from "@/components/board/GridControls.vue";

import BoardSelector from "@/components/board/BoardSelector.vue";
import SingleBoardSettingsButton from "@/components/board/SingleBoardSettingsButton.vue";

import { mapState, mapGetters } from "vuex";

export default {
  name: "PageLayout",
  components: {
    WidgetList,
    GridContainer,
    GridControls,
    BoardSelector,
    SingleBoardSettingsButton,
  },
  computed: {
    ...mapState(["loadedBoard", "pageScrolled"]),
    ...mapGetters([
      "activeBoardId",
      "boardOrientation",
      "multipleBoardsEnabled",
      "editingBoardId",
    ]),
  },
  // FIXME: possible race condition between widgets + groups in beforeCreate and widget instances via board in created.
  beforeMount: async function () {
    await Promise.all([
      this.$store.dispatch("fetchWidgets", ["group"]),
      this.$store.dispatch("fetchBoard", {
        boardId: this.activeBoardId,
        includes: ["widgetInstances"],
      }),
    ]);
    this.currentBoard = this.activeBoardId;
    this.$store.commit("CHANGE_FETCH_STATUS", false);
  },
};
</script>

<style lang="scss">
.board {
  display: grid;
  grid-template-areas:
    "controls"
    "board"
    "widgetbar";
  grid-template-rows: 4.5rem auto 4.5rem;
  grid-template-columns: 100%;
  justify-content: center;
  scrollbar-width: none;
}

.board__controls {
  padding: $global-padding * 0.5;
  display: flex;
  justify-content: space-between;
  grid-area: "controls";
  align-items: center;
  gap: $global-padding;
  position: relative;

  &.scrolled {
    box-shadow: 0 5px 5px -4px rgba(0, 0, 0, 0.1);
  }
}
</style>
