import axios from "axios";
import httpVueLoader from "http-vue-loader";
import normalize from "json-api-normalizer";
import FetchError from "@/components/FetchError";

import {
  buildIncludeString,
  buildFilterString,
  buildBackendUrl,
  denormalize,
} from "@/api/helpers";

// ############ Reusable parameters #################
// TODO: Remove exports once store actions all use this api abstraction
export const normalizerOptions = { camelizeKeys: true };
export const defaultParams = {
  headers: {
    "Content-Type": "application/vnd.api+json",
  },
};
const backendUrl = buildBackendUrl();

/**
 * Checks whether the Settings template is available for a given extension and returns a httpVueLoader instance for it.
 * @param {String} extension Name of the extension
 * @returns {httpVueLoader | FetchError}
 */
function templateAvailable(extType, extension, version = "0.0.0") {
  extension = extension.replace(/-/g, "_");
  const file = `/assets/${extType}/${extension}/templates/settings.vue?version=${version}`;

  const available = axios.head(file).then((res) => {
    return res.status === 200;
  });

  if (!available) return FetchError;

  return httpVueLoader(file);
}

function getSubresource({
  parentResource,
  parentId,
  childResource,
  includes = [],
  filters = [],
}) {
  const includeString = buildIncludeString(includes);
  const filterString = buildFilterString(filters);
  return axios
    .get(
      `/${parentResource}/${parentId}/${childResource}${includeString}${filterString}`
    )
    .then((res) => {
      return normalize(res.data, normalizerOptions);
    });
}

/**
 * Creates a new instance association model resource in the backend.
 * @param {InstanceAssociationResource} instanceAssociation IA model
 * @returns {InstanceAssociationResource}
 */
function createInstanceAssociation(instanceAssociation) {
  const payload = denormalize(instanceAssociation);

  return axios
    .post(`/instance-associations`, payload, defaultParams)
    .then((res) => {
      return normalize(res.data, normalizerOptions);
    });
}

function patchInstanceAssociation(instanceAssociation) {
  const payload = denormalize(instanceAssociation);

  return axios
    .patch(
      `/instance-associations/${instanceAssociation.id}`,
      payload,
      defaultParams
    )
    .then((res) => {
      return normalize(res.data, normalizerOptions);
    });
}

function deleteInstanceAssociation(instanceAssociation) {
  return axios
    .delete(`/instance-associations/${instanceAssociation.id}`)
    .then((res) => {
      return res.code;
    });
}

/**
 * Fetches file contents for extension assets from the backend.
 * @param{Object} params
 * @param {String} params.extType Extension type (widgets/sources)
 * @param {String} params.extension Name of the extension
 * @param {String} params.assetType subfolder of the extension's app/assets directory (e. g. 'icon')
 * @param {String} params.filename filename including extension
 */
async function getExtensionAsset({ extType, extension, assetType, filename }) {
  const res = await axios.get(
    `/assets/${extType}/${extension}/${assetType}/${filename}`
  );
  return res.data;
}

/**
 * Returns the full path to a extension asset to use in source attributes.
 * @param {Object} params the request parameter
 * @param {String} params.extType extension type, e.g. `widget` or `source`
 * @param {String} params.extension extension name
 * @param {String} params.assetType subfolder of the extension's app/assets directory (e. g. 'icon')
 * @param {String} params.filename filename including extension
 */
function getExtensionAssetPath({ extType, extension, assetType, filename }) {
  return `${backendUrl}/assets/${extType}/${extension}/${assetType}/${filename}`;
}

/**
 * Creates a Board.
 * @param {boardResource} boardResource
 */
function createBoard(boardResource) {
  const payload = denormalize(boardResource);

  return axios.post("/boards", payload, defaultParams).then((res) => {
    return normalize(res.data, normalizerOptions);
  });
}

/**
 * Deletes a Board.
 * @param {number|string} boardId
 */
function deleteBoard(boardId) {
  return axios.delete(`/boards/${boardId}`, defaultParams);
}

/**
 *
 * @param {BoardResource} boardResource
 */
function updateBoard(boardResource) {
  const payload = denormalize(boardResource);
  return axios
    .patch(`/boards/${boardResource.id}`, payload, defaultParams)
    .then((res) => {
      return normalize(res.data, normalizerOptions);
    });
}

function deleteWidgetInstanceFromBoard({ boardId, widgetInstanceId }) {
  return axios.delete(
    `/boards/${boardId}/relationships/widget-instances`,
    {
      data: {
        data: [{ id: widgetInstanceId, type: "widget-instances" }],
      },
    },
    defaultParams
  );
}

//FIXME: Signatures for Rule calls differ from other calls!
function getRulesForBoard(boardId) {
  return axios.get(`/boards/${boardId}/rules`);
}

function createRule(resource) {
  return axios.post("/rules", denormalize(resource), defaultParams);
}

function deleteRule(ruleId) {
  return axios.delete(`/rules/${ruleId}`, defaultParams);
}

export const api = {
  templateAvailable: templateAvailable,
  getSubresource: getSubresource,
  createInstanceAssociation: createInstanceAssociation,
  patchInstanceAssociation: patchInstanceAssociation,
  deleteInstanceAssociation: deleteInstanceAssociation,
  getExtensionAsset: getExtensionAsset,
  getExtensionAssetPath: getExtensionAssetPath,
  createBoard: createBoard,
  updateBoard: updateBoard,
  deleteBoard: deleteBoard,
  deleteWidgetInstanceFromBoard: deleteWidgetInstanceFromBoard,
  getRulesForBoard: getRulesForBoard,
  createRule: createRule,
  deleteRule: deleteRule,
};
