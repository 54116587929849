<template>
  <div
    class="grid-stack-item"
    :data-widget-id="widget.id"
    :gs-w="insertionSize.w"
    :gs-h="insertionSize.h"
    :key="widget.id"
    v-draggable
  >
    <div :class="[classes, 'widgetlist__content']">
      <i class="widgetlist__icon svg--white" v-html="widgetIcon" />
      <p class="widgetlist__title">
        {{ localisedAttribute(widget, "title", language) }}
      </p>
    </div>
  </div>
</template>

<script>
import { api } from "@/api/operations";
import { mapGetters, mapState } from "vuex";
import localisedAttribute from "@/mixins/localisedAttribute";

export default {
  name: "WidgetListItem",
  mixins: [localisedAttribute],
  props: {
    widget: {
      type: Object,
      required: true,
    },
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["language"]),
    ...mapState(["gridLocked"]),
    insertionSize: function () {
      // FIXME: Remove conditional once all widgets have a set size.
      const initialSize =
        this.widget.attributes.sizes != null
          ? this.getSmallestHeight(this.widget.attributes.sizes)
          : { w: 4, h: 4 };
      return { w: initialSize.w, h: initialSize.h };
    },
  },
  asyncComputed: {
    widgetIcon: async function () {
      return await api.getExtensionAsset({
        extType: this.widget.type,
        extension: this.widget.id,
        assetType: "icons",
        filename: `${this.widget.id}.svg?version=${this.widget.attributes.version}`,
      });
    },
  },
  methods: {
    getSmallestHeight: function (sizesArray) {
      const heights = sizesArray.reduce(function (acc, size) {
        acc.push(size.h);
        return acc;
      }, []);
      const smallest = heights
        .slice()
        .sort((a, b) => a - b)
        .shift();
      return sizesArray[heights.indexOf(smallest)];
    },
  },
  directives: {
    draggable: {
      bind: function (el, binding, node) {
        binding, node;
        $(el).draggable({
          helper: "clone",
          revert: "invalid",
          appendTo: ".grid-stack",
          opacity: 0.7,
        });
      },
      componentUpdated: function (el, binding, node) {
        el, binding, node;
        $(el).draggable(
          node.context.gridLocked === true ? "disable" : "enable"
        );
      },
    },
  },
};
</script>
<style lang="scss">
.widgetlist__content {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--theme-color);
  padding: 0.625rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;
  font-size: 0.625rem;
  white-space: nowrap;

  &.locked {
    background-color: $light-gray;
  }

  &.static {
    width: 3.5rem;
    height: 3.5rem;
  }
  &.dragging {
    width: 100%;
    height: 100%;
    background-color: green;
    transition: all 0.25s ease-in-out 0.25s;
  }
}

.widgetlist__icon {
  width: 1.25rem;
}
.widgetlist__icon--black {
  color: $black;
}

.widgetlist__title {
  margin: 0;
  hyphens: auto;
  color: $white;
}
</style>
