<template functional>
  <!-- @event change -->
  <fieldset class="switch" @change="listeners.change">
    <label :for="`${props.fieldName}__option_off`">Off</label>
    <input
      :id="`${props.fieldName}__option_off`"
      type="radio"
      :name="props.fieldName"
      :checked="!props.active"
      value="off"
    />
    <input
      :id="`${props.fieldName}__option_on`"
      type="radio"
      :name="props.fieldName"
      :checked="props.active"
      value="on"
    />
    <label :for="`${props.fieldName}__option_on`">On</label>
    <span class="toggle-outside">
      <span class="toggle-inside">
        <!-- @slot Optional icon to display on top of the toggle, see mirr.OS Board screen locker -->
        <slot name="icon" />
      </span>
    </span>
  </fieldset>
</template>

<script>
/**
 * Switch-style toggle for turning a setting on or off.
 */
export default {
  name: "FormControlToggle",
  props: {
    /**
     * form input name attribute. Pass the name of the configuration variable that you want to toggle, e.g. showMotivation.
     */
    fieldName: {
      type: String,
      required: true,
      default: "",
    },
    /**
     * Sets the current state of the form control.
     */
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.switch {
  width: 3.25rem;
  height: 2rem;
  margin: 0;
  font-size: 0;
  position: relative;

  & label {
    display: none;
  }

  & input {
    height: 3rem;
    width: 4rem;
    margin: 0;
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:checked {
      z-index: 1;
    }
    &:not(:checked) + label:hover {
      opacity: 0.5;
    }
  }

  & .toggle-outside {
    height: 100%;
    border-radius: 2rem;
    padding: 0.25rem;
    overflow: hidden;
    transition: 0.25s ease all;
    position: absolute;
    width: 3.25rem;
  }
  & .toggle-inside {
    border-radius: 5rem;
    box-shadow: 0 0 1px 0 $secondary-color;
    background: $white;
    position: absolute;
    top: 0.125rem;
    transition: 0.25s ease all;
    height: 1.75rem;
    width: 1.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & input:checked ~ .toggle-outside {
    background-color: rgba(var(--theme-color-rgb), 0.3);

    .toggle-inside {
      left: 0.125rem;
    }
  }
  & input ~ input:checked ~ .toggle-outside {
    background-color: var(--theme-color);

    .toggle-inside {
      left: 1.35rem;
    }
  }
}
</style>
